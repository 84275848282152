/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {HashLink as Link} from 'react-router-hash-link';

export default function Footer() {

    return (
        <footer id="footer" className="mt-5 bg-white">

            <div className="rights">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center py-3">
                            <p className="mb-0">© 2024 Aidi Finance. All Rights Reserved. Aidiverse.com</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

