const ipfsClient = require('ipfs-http-client');
const auth = 'Basic ' + Buffer.from('1wOWE61oXRZEdYHVURc7a2AtLG0' + ':' + '16076175559bc38385a975ae9d07abe7').toString('base64');

const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    apiPath: '/api/v0',
    headers: {
      authorization: auth
    }
  });
export default ipfs;