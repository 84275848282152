import moment from 'moment';


export function SecondsToDay(value) {

	if(value && value !==""){
		var dateString = value;
		//console.log("dateString:", dateString);
		var dateFuture = new Date(dateString);
		//console.log("dateFuture: ", dateFuture);

		var dateNow = new Date();
		//console.log("dateNow: ", dateNow);

		if(dateNow<dateFuture)
		{
			var seconds = Math.floor((dateFuture - (dateNow))/1000);
			var minutes = Math.floor(seconds/60);
			var hours = Math.floor(minutes/60);
			var days = Math.floor(hours/24);
	
			hours = hours-(days*24);
			minutes = minutes-(days*24*60)-(hours*60);
			seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
	
			var dDisplay = days > 0 ? days + (days === 1 ? " d " : " d ") : "";
			var hDisplay = hours > 0 ? hours + (hours === 1 ? " h " : " h ") : "";
			var mDisplay = minutes > 0 ? minutes + (minutes === 1 ? " m " : " m ") : "";
			 seconds = seconds > 0 ? seconds + (seconds === 1 ? " s " : " s ") : "";
	
			if(dDisplay===0 && hDisplay===0 && mDisplay===0){
				return seconds;
			}else{
				return dDisplay + hDisplay + mDisplay;
			}
		}else{
			return '-';
		}

	}else{
		return '-';
	}
}