import React from "react";
import { Triangle } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="c-loader">
      <Triangle color="#0062cc" height={150} width={150} />
    </div>
  );
}
