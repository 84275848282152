import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import merge from 'lodash.merge';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';


import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import Homepage from "./pages/Home"
import Header from "./pages/components/Header";
import Footer from "./pages/components/Footer";
import Details from "./pages/Details";
import Create from "./pages/Create";
import MyRaffles from "./pages/MyRaffles";
import FAQ from "./pages/Faq";
import ScrollToTop from "./pages/components/ScrollToTop"
import config from "./lib/config";

import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  Theme
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient } = configureChains(
    [polygon,mainnet],
    [
      alchemyProvider({ apiKey: config.AlchemyApiKey }),
      publicProvider()
    ]
  );
  const { connectors } = getDefaultWallets({
    appName: 'AidiCraft',
    projectId: config.WcProjectID,
    chains
  });
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  });

  const myTheme = merge(darkTheme(), {
    colors: {
      accentColor: '#1a62e0',
      accentColorForeground: 'white',
    },
    fonts: {
      body: 'Open Sans, sans-serif',
    },
    radii: {
      actionButton: '16px',
      connectButton: '16px',
    },
  });

ReactDOM.render(
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider 
      theme={myTheme}
      chains={chains}>
    <BrowserRouter basename="/">
        <ToastContainer/>
        <ScrollToTop/>
        <div className="position-sticky" id="mtInfo">
            <Header/>

            <Switch>
                <Route path="/home" component={Homepage}/>
                <Route path="/details/:raffleid" component={Details}/>
                <Route path="/create" component={Create}/>
                <Route path="/myraffle" component={MyRaffles}/>
                <Route path="/faq" component={FAQ}/>
                <Route exact path="/*" component={Homepage}>
                    <Redirect to="/home"/>
                </Route>
            </Switch>

            <Footer/>
        </div>
    </BrowserRouter>
    </RainbowKitProvider>
    </WagmiConfig>,
    document.getElementById("root")
);


