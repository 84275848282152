import React, { useRef, useEffect, useState }from "react";
import {Link} from "react-router-dom";
import config, {getDecimals, getTransferFee}  from "../../lib/config";
import {SecondsToDay} from "../../actions/dateFormat";
import constants from "../../lib/constants";
import BigNumber from "bignumber.js";

export default function CardItem(props) {
    var [Network, set_Network] = React.useState("Ethereum");
    var {item} = props;
    var endDateMS = 0;
    var tokenName = "WMATIC";
    if(item && item.Enddate !== 0)
    {
        endDateMS = Number(item.Enddate)*1000;
    }
    if(item && item.TokenAdd)
    {
        var tokenadd= item.TokenAdd.split("-");
        tokenName = tokenadd[0];
    }

    var state = constants.TICKER_STATE_UNKNOWN;
    var startTime = 0;
    var endTime = 0;
    var now = new Date();

    if(item.StartDate === 0)
    {
        startTime = 0;
    }else{
        startTime = new Date(Number(item.StartDate)*1000);
    }

    if(item.Enddate === 0)
    {
        endTime = 0;
    }else{
        endTime = new Date(Number(item.Enddate)*1000);
    }

    if((startTime==0 || now>startTime) && (endTime == 0 || now < endTime)) //OPEN
    {
        state = constants.TICKER_STATE_OPEN;
    }

    var date = new Date();
    var tomorrow = date.setDate(date.getDate() + 1);

    if(endTime>0 && (endTime> now) && (endTime < tomorrow)) //CLOSING SOON
    {
        state = constants.TICKER_STATE_CLOSING_SOON;
    }

    if(startTime>0 && (startTime> now) && (startTime < tomorrow)) //STARTTING SOON
    {
        state = constants.TICKER_STATE_STARTING_SOON;
    }

    var decimals = new BigNumber(10).pow(getDecimals(tokenName));

    var entriesLength = Number((new BigNumber(item.TotalEntry.toString())
    .dividedBy(new BigNumber(item.EntryFee)
    .multipliedBy(decimals)))
    .integerValue().toString());

    var minEntry = Number(item.Min_entry);

    if((entriesLength >= minEntry) 
    || (endTime > 0 && now > endTime))//DRAWING SOON
    {
        state = constants.TICKER_STATE_DRAWING_SOON;
    }

    if(item.Raffleclose) //CLOSED
    {
        state = constants.TICKER_STATE_CLOSED;
    }
    
    return (
        <Link to={item && item.Raffle_id ? "/details/" + item.Raffle_id : ""} className="c-card-hoverable card">
            {state && state === constants.TICKER_STATE_DRAWING_SOON && (
            <div className="wrap">
                <span className="ribbon bg-drawing-soon">Drawing Soon
                    <div>
                        <div className="glow">&nbsp;</div>
                    </div>
                </span>
            </div>)}
            {state && state === constants.TICKER_STATE_CLOSING_SOON && (
            <div className="wrap">
                <span className="ribbon bg-closes-soon">Closes Soon
                    <div>
                        <div className="glow">&nbsp;</div>
                    </div>
                </span>
            </div>)}
            {state && state === constants.TICKER_STATE_CLOSED && (
             <div className="wrap">
                <span className="ribbon bg-closed">Closed
                    <div>
                        <div className="glow">&nbsp;</div>
                    </div>
                </span>
            </div>)}
            {state && state === constants.TICKER_STATE_OPEN && (
             <div className="wrap">
                <span className="ribbon bg-open">Open
                    <div>
                        <div className="glow">&nbsp;</div>
                    </div>
                </span>
            </div>)}
            {state && state === constants.TICKER_STATE_STARTING_SOON && (
            <div className="wrap">
                <span className="ribbon bg-starting-soon">Starting Soon
                    <div>
                        <div className="glow">&nbsp;</div>
                    </div>
                </span>
            </div>)}
            <div className="card-cover fl-cc pt-5">
                {item && item.Image ? (
                    <img
                        className="img-fluid"
                        loading="lazy"
                        src={config.IPFS_URL + item.Image}
                        alt="#"
                    />
                ) : (
                    <img
                        className="img-fluid"
                        loading="lazy"
                        src={require("../../assets/images/aidilogo.png")}
                        alt="#"
                    />
                )}
            </div>
            <div>
                <div className="divider-horizontal" role="separator"/>
            </div>
            <div className="card-body">
                <span className="c-card-header">{item && item.SubTitle}</span>
                {/* {props && props.tempdata && props.tempdata.value} */}
                <br/>
                <h3 className="typography">{item && item.Title}</h3>
                <p className="text-ellipsis">
                    {item && item.Description && item.Description.slice(0.1)}
                    {/* lorem ipsum dolor sit amet, consectetur adipis ipsum dolor sit amet, consectetur adipis */}
                </p>
                <br/>
                <div className="row">
                    <div className="col-6">
                        <div className="statistic">
                            <div className="statistic-title">
                                <h5 className="typography mb-0">
                                    <span className="">Ticket Price
                                        <span
                                          className="pointer-cursor ms-2"
                                          data-toggle="tooltip"
                                          title="Raffle ticket price of one entry"
                                        >
                                          <i className="far fa-question-circle text-muted" />
                                        </span>
                                    </span>
                                </h5>
                            </div>
                            <div className="statistic-content">
                                <span className="statistic-content-value">
                                  {item && item.EntryFee} {tokenName}
                                </span>
                            </div>
                        </div>
                    </div>

                    {item && item.Enddate !== 0 && (
                        <div className="col-6">
                            <div className="statistic">
                                <div className="statistic-title">
                                    <h5 className="typography mb-0">
                                        <span className="">Closes in</span>
                                    </h5>
                                </div>
                                <div className="statistic-content">
                                  <span className="statistic-content-value">
                                    {SecondsToDay(endDateMS)}
                                  </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {item && item.Enddate === 0 && (
                        <div className="col-6">
                            <div className="statistic">
                                <div className="statistic-title">
                                    <h5 className="typography mb-0">
                                        <span className="">Closes when</span>
                                    </h5>
                                </div>
                                <div className="statistic-content">
                                  <span className="statistic-content-value">
                                    Owner Draws
                                  </span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-6">
                        <div className="statistic">
                            <div className="statistic-title">
                                <h5 className="typography mb-0">
                                    <span className="">Prize
                                        <span
                                          className="pointer-cursor ms-2"
                                          data-toggle="tooltip"
                                          title="Percentage of total collected raffle entry funds that will be split equally among all the winners"
                                        >
                                          <i className="far fa-question-circle text-muted" />
                                        </span>
                                    </span>
                                </h5>
                            </div>
                            <div className="statistic-content">
                              <span className="statistic-content-value">
                              {item && item.RewardPerc}%
                              </span>
                            </div>
                        </div>
                    </div>
                    {item && item.Bought && Number(item.Bought) > 0 ? (
                         <div className="col-6">
                        <div className="statistic">
                        <div className="statistic-title">
                            <h5 className="typography mb-0">
                                <span className="">Purchased
                                    <span
                                      className="pointer-cursor ms-2"
                                      data-toggle="tooltip"
                                      title="Total entries purchased in this raffle"
                                    >
                                      <i className="far fa-question-circle text-muted" />
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                          {Number(item.Bought) == 1
                                    ? `${item.Bought} entry`
                                    : `${item.Bought} entries`
                                }
                          </span>
                        </div>
                        </div>
                        </div>):``}
                    </div>
                {/* <div className="progress-line">
          <div className="progress-outer">
            <div className="progress-inner">
              <div className="progress-bg" />
            </div>
          </div>
        </div> */}

            </div>
        </Link>
    );
}
