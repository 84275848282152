
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeBanner from "./components/HomeBanner";
import CardItem from "./components/CardItem";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { toast } from "react-toastify";
import config from "../lib/config";
import Loader from "./components/Loader";
import Web3 from "web3";
import "@metamask/legacy-web3";
import moment from "moment";
import axios from "axios";

import RAFFLE_ABI from "../Abi/Raffle.json";

import { getReceipt } from "../actions/web3actions";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
toast.configure();
let toasterOption = config.toasterOption;
const RAFFLE_ADDRESS = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? config.RAFFLE_CONTRACT_ETH: config.RAFFLE_CONTRACT : config.RAFFLE_CONTRACT_ETH;


var web3 = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? new Web3(config.INFURA_URL): new Web3(config.INFURA_URL_POLY) : new Web3(config.INFURA_URL);


var RAFFLE_SMART_CONTRACT = new web3.eth.Contract(RAFFLE_ABI, RAFFLE_ADDRESS);

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

function MyRaffles() {
  var { address, isConnected } = useAccount();
  const [Account, set_Accounts] = useState("");
  const [ETHBalance, set_ETHBalance] = useState(0);
  const [CreateFee, set_CreateFee] = useState(0);
  const [Loading, set_Loading] = useState(false);
  const [AllRaffleIds, set_AllRaffleIds] = useState([]);
  const [MyRaffles, set_MyRaffles] = useState([]);
  const [PartRaffles, set_PartRaffles] = useState([]);
  const [AllRaffles, set_AllRaffles] = useState([]);

  if(config.currentNetwork == "Ethereum")
  {
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT_ETH
    );

  }else{
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT
    );
  }
  if (config.provider)
  {
    web3 = new Web3(config.provider);
  }
    

  function showLoader() {
    set_Loading(true);
  }
  function hideLoader() {
    set_Loading(false);
  }

  async function CheckWalletConnected() {

    if (config.provider)
    {
      showLoader();
      web3 = await new Web3(config.provider);
      if (web3) {
        const chainId = await web3.eth.getChainId();
        if (chainId.toString() == "1") {
          config.currentNetwork = "Ethereum";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT_ETH
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
        }else if (chainId.toString() == "137") {
          config.currentNetwork = "Polygon";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
        }
      }
    }
  }
  

  useEffect(async () => {
    var acc = localStorage.getItem("Account");        
    if(acc && acc.toString().length>40)
    {
      set_Accounts(acc);
    }
    await  CheckWalletConnected(); 
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (!localdata) {
      toast.error("Please Connect With Wallet to Continue...");

      setTimeout(() => {
        window.location.href = "/home";
      }, 1000);
    }

    var createfee = await RAFFLE_SMART_CONTRACT.methods
      .raffleCreateFee()
      .call();
    set_CreateFee(createfee);
  }, [localStorage.getItem],
  [address, isConnected]);

  useEffect(async () => {
    if (Account !== "") {
      try {
        var raffleids = await RAFFLE_SMART_CONTRACT.methods
          .getAllActiveRaffles()
          .call();

        set_AllRaffleIds(raffleids);
        if (raffleids && raffleids.length > 0) {
          var AllRaffleData = [];
          var OwnRaffle = [];
          var ParRaffles = [];
          for (var i = 0; i < raffleids.length; i++) {
            var tempdetails = await RAFFLE_SMART_CONTRACT.methods
              .raffles(raffleids[i])
              .call();
            //console.log("tempdetailstempdetails", tempdetails);

            var TotalEntry = await RAFFLE_SMART_CONTRACT.methods
            .getTotalEntryFeesCollected(raffleids[i])
            .call();
            var Raffleclose = await RAFFLE_SMART_CONTRACT.methods
            .isRaffleClosed(raffleids[i])
            .call();
            var TotalEntryForAddress = 0;
            try {
              if(Account)
              {
                TotalEntryForAddress = await RAFFLE_SMART_CONTRACT.methods
                .getEntriesForAddressInRaffle(raffleids[i], Account)
                .call();
                TotalEntryForAddress = Number(TotalEntryForAddress.toString());
              }
            } catch (error) {
            }
          
            if (tempdetails) {
             
              var ipfsresponse = await axios.get(
                `${config.IPFS_URL}` + tempdetails.ipfsdetails
              );
              if (ipfsresponse && ipfsresponse.status === 200) {

                ipfsresponse= JSON.parse(ipfsresponse.data.content);
                
                var userparticipateornot = await RAFFLE_SMART_CONTRACT.methods
                .isUserOwnerOrPartcipating(raffleids[i], Account)
                .call();
                //console.log("🚀 ~ file: MyRaffles.js ~ line 102 ~ useEffect ~ userparticipateornot",userparticipateornot)
             
              if (userparticipateornot === "2") {
                OwnRaffle.push({ 
                    TokenAdd: ipfsresponse.Raffle_Token,
                    EntryFee: ipfsresponse.Raffle_EntFee,
                    Min_entry: ipfsresponse.Raffle_MinEntry,
                    Max_entry: ipfsresponse.Raffle_MaxEntry,
                    Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                    RewardPerc: ipfsresponse.Raffle_RewardPerc,
                    NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                    Description: ipfsresponse.Raffle_desc,
                    Image: ipfsresponse.Raffle_image,
                    Title: ipfsresponse.Raffle_title,
                    SubTitle: ipfsresponse.Raffle_subtitle,
                    StartDate: ipfsresponse.Raffle_startdate,
                    Enddate: ipfsresponse.Raffle_enddate,
                    Raffle_id: raffleids[i],
                    TotalEntry: TotalEntry,
                    Raffleclose: Raffleclose,
                    Bought:TotalEntryForAddress
                })
              } else if (userparticipateornot ==="3") {
                OwnRaffle.push({ 
                  TokenAdd: ipfsresponse.Raffle_Token,
                  EntryFee: ipfsresponse.Raffle_EntFee,
                  Min_entry: ipfsresponse.Raffle_MinEntry,
                  Max_entry: ipfsresponse.Raffle_MaxEntry,
                  Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                  RewardPerc: ipfsresponse.Raffle_RewardPerc,
                  NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                  Description: ipfsresponse.Raffle_desc,
                  Image: ipfsresponse.Raffle_image,
                  Title: ipfsresponse.Raffle_title,
                  SubTitle: ipfsresponse.Raffle_subtitle,
                  StartDate: ipfsresponse.Raffle_startdate,
                  Enddate: ipfsresponse.Raffle_enddate,
                  Raffle_id: raffleids[i],
                  TotalEntry: TotalEntry,
                  Raffleclose: Raffleclose,
                  Bought:TotalEntryForAddress
              })
                ParRaffles.push({ 
                    TokenAdd: ipfsresponse.Raffle_Token,
                    EntryFee: ipfsresponse.Raffle_EntFee,
                    Min_entry: ipfsresponse.Raffle_MinEntry,
                    Max_entry: ipfsresponse.Raffle_MaxEntry,
                    Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                    RewardPerc: ipfsresponse.Raffle_RewardPerc,
                    NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                    Description: ipfsresponse.Raffle_desc,
                    Image: ipfsresponse.Raffle_image,
                    Title: ipfsresponse.Raffle_title,
                    SubTitle: ipfsresponse.Raffle_subtitle,
                    StartDate: ipfsresponse.Raffle_startdate,
                    Enddate: ipfsresponse.Raffle_enddate,
                    Raffle_id: raffleids[i],
                    TotalEntry: TotalEntry,
                    Raffleclose: Raffleclose,
                    Bought:TotalEntryForAddress
                })
              }
              else if (userparticipateornot ==="1") {
                ParRaffles.push({ 
                  TokenAdd: ipfsresponse.Raffle_Token,
                  EntryFee: ipfsresponse.Raffle_EntFee,
                  Min_entry: ipfsresponse.Raffle_MinEntry,
                  Max_entry: ipfsresponse.Raffle_MaxEntry,
                  Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                  RewardPerc: ipfsresponse.Raffle_RewardPerc,
                  NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                  Description: ipfsresponse.Raffle_desc,
                  Image: ipfsresponse.Raffle_image,
                  Title: ipfsresponse.Raffle_title,
                  SubTitle: ipfsresponse.Raffle_subtitle,
                  StartDate: ipfsresponse.Raffle_startdate,
                  Enddate: ipfsresponse.Raffle_enddate,
                  Raffle_id: raffleids[i],
                  TotalEntry: TotalEntry,
                  Raffleclose: Raffleclose,
                  Bought:TotalEntryForAddress
              })
              }

                AllRaffleData.push({
                  TokenAdd: ipfsresponse.Raffle_Token,
                  EntryFee: ipfsresponse.Raffle_EntFee,
                  Min_entry: ipfsresponse.Raffle_MinEntry,
                  Max_entry: ipfsresponse.Raffle_MaxEntry,
                  Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                  RewardPerc: ipfsresponse.Raffle_RewardPerc,
                  NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                  Description: ipfsresponse.Raffle_desc,
                  Image: ipfsresponse.Raffle_image,
                  Title: ipfsresponse.Raffle_title,
                  SubTitle: ipfsresponse.Raffle_subtitle,
                  StartDate: ipfsresponse.Raffle_startdate,
                  Enddate: ipfsresponse.Raffle_enddate,
                  Raffle_id: raffleids[i],
                  TotalEntry: TotalEntry,
                  Raffleclose: Raffleclose,
                  Bought:TotalEntryForAddress
                });
              }
            }
          }
          //console.log("AllRaffleDataAllRaffleDataAllRaffleData", AllRaffleData);
          //console.log("🚀 ~ file: MyRaffles.js ~ line 161 ~ useEffect ~ OwnRaffle", OwnRaffle)
          //console.log("🚀 ~ file: MyRaffles.js ~ line 162 ~ useEffect ~ ParRaffles", ParRaffles)
          set_MyRaffles(OwnRaffle)
          set_PartRaffles(ParRaffles)
          set_AllRaffles(AllRaffleData)
          hideLoader();
        }
        hideLoader()
      } catch (e) {
        hideLoader();
        console.log("erererere", e);
      }
    }else
    {
      var acc = localStorage.getItem("Account");        
      if(acc && acc.toString().length>40)
      {
        set_Accounts(acc);
      }
    }
  }, [Account]);

  return (
    <div className="App position-relative mt-nav">
      {Loading &&
      <Loader />}
      <div className="h-100vh bg-homebanner position-relative">
        {/* <HomeBanner /> */}

        <div className="main-center container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="font-weight-bold mb-5">Owned Raffles</h3>
            </div>

            {MyRaffles&&MyRaffles.length>0&&
           
                 MyRaffles.map((item) => {
                     return(
                      <div className="col-12 col-md-6 col-lg-4">
                        <CardItem 
                        item={item}/>
                          </div>
                     )
             
                 })
                
          
}

            {/* <div className="col-12 col-md-6 col-lg-4">
              <CardItem />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <CardItem />
            </div> */}
            {MyRaffles&&MyRaffles.length===0&&
             <div className="p-5 text-center position-relative">
             <h4 className="font-weight-bold text-primary">
               No Raffles Created..
             </h4>
           </div>
            }

            {Account === "" && (
              <div className="p-5 text-center position-relative">
                <h4 className="font-weight-bold text-primary">
                  Wallet Not Connected
                </h4>
                <p>Please connect to your Wallet</p>
              </div>
            )}
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="font-weight-bold mb-5">Participating Raffles</h3>
            </div>
            {PartRaffles&&PartRaffles.length>0&&
           
           PartRaffles.map((item) => {
               return(
                <div className="col-12 col-md-6 col-lg-4">
                  <CardItem 
                  item={item}/>
                    </div>
               )
       
           })
}
{PartRaffles&&PartRaffles.length===0&&
             <div className="p-5 text-center position-relative">
             <h4 className="font-weight-bold text-primary">
               Not Participating in Any Raffles..
             </h4>
           </div>
            }
            {/* <div className="col-12 col-md-6 col-lg-4">
              <CardItem />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <CardItem />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <CardItem />
            </div> */}
          </div>
        </div>
      </div>

      <a href="javascript:" onClick={topFunction} id="return-to-top">
        <i className="icofont icofont-arrow-up" />
      </a>
    </div>
  );
}

export default MyRaffles;
