import Particles from "react-tsparticles";



export default function HomeBanner() {
    const particlesInit = (main) => {
        // console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    };

    const particlesLoaded = (container) => {
        // console.log(container);
    };

    return (
        <Particles
            style={{position: "unset"}}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fullScreen: true,
                background: {
                    color: {
                        value: "#ffffff",
                    },
                    image:  "-webkit-linear-gradient(bottom, #9357cc 0%, #2989d8 50%, #2cc99d 100%)",
                    size: "100% 100%",
                    repeat: "no-repeat"
                },
                backgroundMask: {
                    enable: true,
                    cover: {
                      value: {
                        r: 255,
                        g: 255,
                        b: 255
                      }
                    }
                  },
                startSlide: "first",
                endSlide: "last",
                zIndex: "1",
                sliderType: "hero",
                jsFileLocation: "revolution/js/",
                sliderLayout: "fullscreen",
                dottedOverlay: "none",
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "bubble",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        }
                    },
                    modes: {
                        grab: {
                            distance: 400,
                            line_linked: {
                                opacity: 0.5
                            }
                        },
                        bubble: {
                            distance: 400,
                            size: 100,
                            opacity: 1
                        },
                        repulse: {
                            distance: 75
                        }
                    },
                    responsive: {
                        maxWidth: 768,
                        particles: {
                            number: {
                                value: 100
                            }
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 0.5
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 100,
                                opacity: 1
                            },
                            repulse: {
                                distance: 75
                            }
                        },
                    }
                },
                particles: {
                    number: { density: { enable: true, value_area: 800 }, value: 300 },
                    color: {
                        value: "#000000"
                    },
                    shape: {
                        type: "circle",
                        stroke: {
                            width: 0,
                            color: "#ffffff",
                            opacity: 1
                        },
                        image: {
                            src: ""
                        }
                    },
                    opacity: {
                        value: 0.1,
                        random: false,
                        min: 0.25,
                        anim: {
                            enable: false,
                            speed: 1,
                            opacity_min: 0,
                            sync: false
                        }
                    },
                    size: {
                        value: 1,
                        random: true,
                        min: 0.5,
                        anim: {enable: false, speed: 100, size_min: 1, sync: false}
                    },
                    line_linked: {enable: true, distance: 80, color: "#41a8bc", opacity: 0.8, width: 1},
                    move: {
                        enable: true,
                        speed: 5,
                        direction: "right",
                        random: true,
                        min_speed: 6,
                        straight: false,
                        out_mode: "out"
                    }
                },
                detectRetina: true,

            }}
        />
    );
};