// import ipfsClient from "ipfs-http-client";

// import { create } from "ipfs-http-client";

// const client = create('https://ipfs.infura.io:5001/api/v0');



// let ipfs = ipfsClient({
//   host: "ipfs.infura.io",
//   port: "5001",
//   protocol: "https",
//   method: "POST",
//   auth: "1wOWE61oXRZEdYHVURc7a2AtLG0:16076175559bc38385a975ae9d07abe7",
// });

export const getReceipt = async (web3, approveCall) => {
  var receipt = await web3.eth.getTransactionReceipt(approveCall);
  return receipt;
};

// export const ipfsImageHashGet = async (req, res) => {

// 	console.log("line18 ssss",req.body)
// 	console.log("line18 ssss",req.file)
// 	console.log("line18 ssss",req.files)
//   const file = {
//     path: "aidicraft",
//     content: Buffer.from(req.files.Image.data),
//   };
//   const filesAdd = await client.add(file);

//   console.log("filesAddfilesAddfilesAdd", filesAdd);
//   var ipfsimage = filesAdd.cid.string;
//   console.log(ipfsimage);
//   return res.json({ ipfsval: ipfsimage });
// };
