
import React, { useRef, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeBanner from "./components/HomeBanner";
import { Link, useParams, useHistory } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import constants from "../lib/constants";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import config, { getDecimals, getTransferFee } from "../lib/config";
import axios from "axios";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Web3 from "web3";
import RAFFLE_ABI from "../Abi/Raffle.json";
import TOKEN_ABI from "../Abi/Token.json";
import { SecondsToDay } from "../actions/dateFormat";
import BigNumber from "bignumber.js";
import Loader from "./components/Loader";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
toast.configure();
let toasterOption = config.toasterOption;
const RAFFLE_ADDRESS = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? config.RAFFLE_CONTRACT_ETH: config.RAFFLE_CONTRACT : config.RAFFLE_CONTRACT_ETH;


var web3 = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? new Web3(config.INFURA_URL): new Web3(config.INFURA_URL_POLY) : new Web3(config.INFURA_URL);


var RAFFLE_SMART_CONTRACT = new web3.eth.Contract(RAFFLE_ABI, RAFFLE_ADDRESS);

window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  let toTop = document.getElementById("return-to-top");
  if (toTop != null && toTop.style != null && toTop.style.display != null) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      toTop.style.display = "block";
    } else {
      toTop.style.display = "none";
    }
  }
}

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export default function Details() {
  var { address, isConnected } = useAccount();
  var { raffleid } = useParams();
  const [RaffleId, Set_RaffleId] = useState(raffleid);
  const [MaxPerUser, Set_MaxPerUser] = useState(0);
  const [RemainingBuys, Set_RemainingBuys] = useState(0);
  const [raffleQty, Set_raffleQty] = useState(1);
  const [RaffleData, Set_RaffleData] = useState({});
  const [ButtonLoader, set_ButtonLoader] = useState(false);
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState(false);

  const [Account, set_Account] = useState("");
  const [TokenBalance, set_TokenBalance] = useState(0);
  const [TotalPrize, set_TotalPrize] = useState(0);
  const [Enddate, set_Enddate] = useState(0);
  const [TickerState, set_TickerState] = useState(0);

  const [RaffleClosed, set_RaffleClosed] = useState(false);
  const [RaffleDeleted, set_RaffleDeleted] = useState(false);

  const [TotalEntry, set_TotalEntry] = useState(0);
  const [TotalEntryLength, set_TotalEntryLength] = useState(0);
  const [TotalEntryForAddress, set_TotalEntryForAddress] = useState(0);
  const [MaxEntry, set_MaxEntry] = useState(0);
  const [WinnerShare, set_WinnerShare] = useState(0);

  const [isComplete, set_isComplete] = useState(true);
  const [RaffleWinners, Set_RaffleWinners] = useState([]);
  const [IsUserParticipating, Set_IsUserParticipating] = useState(false);
  const [EntryFee, set_EntryFee] = useState(0);
  const [MaximumBought, set_MaximumBought] = useState(false);
  const [IMaximumBought, set_IMaximumBought] = useState(false);
  const [Loading, set_Loading] = useState(false);
  var [Network, set_Network] = React.useState("Ethereum");
  function showLoader() {
    set_Loading(true);
  }
  function hideLoader() {
    set_Loading(false);
  }

  useEffect(
    () => {
      CheckWalletConnected();
    },
    [localStorage.getItem("ConnectionStatus1")],
    RaffleData,
    [address, isConnected]
  );
  if(config.currentNetwork == "Ethereum")
  {
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT_ETH
    );

  }else{
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT
    );
  }
  if (config.provider)
  {
    web3 = new Web3(config.provider);
  }

  async function CheckWalletConnected() {

    if (config.provider)
    {
      showLoader();
      web3 = await new Web3(config.provider);
      if (web3) {
        const chainId = await web3.eth.getChainId();
        if (chainId.toString() == "1") {
          config.currentNetwork = "Ethereum";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT_ETH
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Account(result[0]);
          });
        }else if (chainId.toString() == "137") {
          config.currentNetwork = "Polygon";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Account(result[0]);
          });
        }
      }
    }
  }

  useEffect(async () => {
    try {
      if (RaffleId) {
        var RaffleData = {};
        var tokenName = "WMATIC";

        var tempdetails = await RAFFLE_SMART_CONTRACT.methods
          .raffles(RaffleId)
          .call();

        var Raffleclose = await RAFFLE_SMART_CONTRACT.methods
          .isRaffleClosed(RaffleId)
          .call();
        set_RaffleClosed(Raffleclose);

        var Raffledelete = await RAFFLE_SMART_CONTRACT.methods
          .isRaffleDeleted(RaffleId)
          .call();
        set_RaffleDeleted(Raffledelete);

        if (Raffleclose) {
          GetWinners(RaffleId);
        }

        set_isComplete(tempdetails.isComplete);

        if (Raffleclose) {
          var winnerArray = await RAFFLE_SMART_CONTRACT.methods
            .getRaffleWinners(RaffleId)
            .call();
          //console.log("winnerArraywinnerArraywinnerArray", winnerArray);
          Set_RaffleWinners(winnerArray);
        }

        if (Account) {
          var userparticipateornot = await RAFFLE_SMART_CONTRACT.methods
            .isUserOwnerOrPartcipating(RaffleId, Account)
            .call();
          if (userparticipateornot === "2" || userparticipateornot === "3") {
            Set_IsUserParticipating(true);
          }
        }

        if (tempdetails) {
          //console.log(
          //   "🚀 ~ file: Details.js ~ line 134 ~ useEffect ~ tempdetails",
          //   tempdetails
          // );
          var ipfsresponse = await axios.get(
            `${config.IPFS_URL}` + tempdetails.ipfsdetails
          );

          if (ipfsresponse && ipfsresponse.status === 200) {
            //console.log(
            //   "🚀 ~ file: Details.js ~ line 140 ~ useEffect ~ ipfsresponse",
            //   ipfsresponse
            // );
            ipfsresponse = JSON.parse(ipfsresponse.data.content);
            var tokenName = ipfsresponse.Raffle_Token.split("-");
            RaffleData.TokenName = tokenName[0];
            RaffleData.TokenAdd = tokenName[1];
            RaffleData.RewardPerc = ipfsresponse.Raffle_RewardPerc;

            var decimals = new BigNumber(10).pow(
              getDecimals(RaffleData.TokenName)
            );

            var totalcolected = new BigNumber(
              tempdetails.entryFeesCollected
            ).dividedBy(decimals);
            var rewardperc = new BigNumber(RaffleData.RewardPerc);
            let totalreward = totalcolected
              .multipliedBy(rewardperc)
              .dividedBy(100);
            set_TotalPrize(totalreward.toFixed(2));

            RaffleData.EntryFee = ipfsresponse.Raffle_EntFee;
            set_EntryFee(RaffleData.EntryFee);
            RaffleData.Min_entry = ipfsresponse.Raffle_MinEntry;
            RaffleData.Max_entry = ipfsresponse.Raffle_MaxEntry;
            set_MaxEntry(Number(RaffleData.Max_entry.toString()));
            RaffleData.Max_Per_user = ipfsresponse.Raffle_MaxPeruser;
            RaffleData.NoOfWinners = ipfsresponse.Raffle_noOfWinners;
            RaffleData.Description = ipfsresponse.Raffle_desc;
            RaffleData.Image = ipfsresponse.Raffle_image
              ? ipfsresponse.Raffle_image
              : "";
            RaffleData.Title = ipfsresponse.Raffle_title;
            RaffleData.SubTitle = ipfsresponse.Raffle_subtitle;
            RaffleData.StartDate = ipfsresponse.Raffle_startdate;
            RaffleData.Enddate = ipfsresponse.Raffle_enddate;
            RaffleData.Raffle_Creator = ipfsresponse.Raffle_Creator;

            Set_MaxPerUser(Number(ipfsresponse.Raffle_MaxPeruser));

            var TotalEntry = await RAFFLE_SMART_CONTRACT.methods
              .getTotalEntryFeesCollected(RaffleId)
              .call();

            var entriesLength = Number(
              new BigNumber(TotalEntry.toString())
                .dividedBy(
                  new BigNumber(RaffleData.EntryFee).multipliedBy(decimals)
                )
                .integerValue()
                .toString()
            );

            set_TotalEntryLength(entriesLength);
            set_TotalEntry(TotalEntry);

            var TotalEntryForAddress = 0;
            if (Account) {
              TotalEntryForAddress = await RAFFLE_SMART_CONTRACT.methods
                .getEntriesForAddressInRaffle(RaffleId, Account)
                .call();
              TotalEntryForAddress = Number(TotalEntryForAddress.toString());
            }
            set_TotalEntryForAddress(TotalEntryForAddress);

            var remaining =
              Number(ipfsresponse.Raffle_MaxPeruser) -
              Number(TotalEntryForAddress);
            Set_RemainingBuys(remaining);

            RaffleData.TotalEntry = TotalEntry;
            RaffleData.TotalEntryLength = entriesLength;

            if (entriesLength >= Number(RaffleData.Max_entry)) {
              set_MaximumBought(true);
            }
            if (
              TotalEntryForAddress > 0 &&
              TotalEntryForAddress >= Number(ipfsresponse.Raffle_MaxPeruser)
            ) {
              set_IMaximumBought(true);
            }

            var WinnerShareVal = Number(
              totalreward
                .dividedBy(new BigNumber(RaffleData.NoOfWinners))
                .toFixed(2)
            );

            set_WinnerShare(WinnerShareVal);
            RaffleData.WinnerShare = WinnerShareVal;

            var Raffleclose = await RAFFLE_SMART_CONTRACT.methods
              .isRaffleClosed(RaffleId)
              .call();
            if (!Raffleclose) {
              if (RaffleData.Enddate != 0) {
                RaffleData.EnddateRemain = Number(RaffleData.Enddate) * 1000;
                RaffleData.EnddateTitle = "Close Time";
                var endTime = new Date(Number(RaffleData.Enddate) * 1000);
                RaffleData.EnddateStr = dateFormat(endTime, "dd/mm/yy h:MM:ss");
              } else {
                RaffleData.EnddateTitle = "Closes when";
                RaffleData.EnddateStr = "Owner Draws";
              }
            } else {
              RaffleData.EnddateTitle = "End Status";
              RaffleData.EnddateStr = "Closed";
            }
            if (RaffleData.StartDate !== 0) {
              RaffleData.StartDateRemain = Number(RaffleData.StartDate) * 1000;
              RaffleData.StartDateTitle = "Start Time";
              RaffleData.StartDateStr = dateFormat(
                new Date(Number(RaffleData.StartDate) * 1000),
                "dd/mm/yy h:MM:ss"
              );
            } else {
              RaffleData.StartDateTitle = "Start Status";
              RaffleData.StartDateStr = "Started";
            }
            set_Enddate(ipfsresponse.Raffle_enddate);
            Set_RaffleData(RaffleData);

            var state = constants.TICKER_STATE_UNKNOWN;
            var startTime = 0;
            var endTime = 0;
            var now = new Date();

            if (RaffleData.StartDate === 0) {
              startTime = 0;
            } else {
              startTime = new Date(Number(RaffleData.StartDate) * 1000);
            }

            if (RaffleData.Enddate === 0) {
              endTime = 0;
            } else {
              endTime = new Date(Number(RaffleData.Enddate) * 1000);
            }

            if (
              (startTime == 0 || now > startTime) &&
              (endTime == 0 || now < endTime)
            ) {
              //OPEN
              state = constants.TICKER_STATE_OPEN;
            }

            var date = new Date();
            var tomorrow = date.setDate(date.getDate() + 1);

            if (endTime > 0 && endTime > now && endTime < tomorrow) {
              //CLOSING SOON
              state = constants.TICKER_STATE_CLOSING_SOON;
            }

            if (startTime > 0 && startTime > now && startTime < tomorrow) {
              //STARTTING SOON
              state = constants.TICKER_STATE_STARTING_SOON;
            }

            var minEntry = Number(RaffleData.Min_entry);

            if (entriesLength >= minEntry || (endTime > 0 && now > endTime)) {
              //DRAWING SOON
              state = constants.TICKER_STATE_DRAWING_SOON;
            }

            if (Raffleclose) {
              //CLOSED
              state = constants.TICKER_STATE_CLOSED;
            }

            set_TickerState(state);

            if (
              RaffleWinners.length > 0 &&
              !localStorage.getItem("winner_popup_" + Account + "_" + RaffleId)
            ) {
              for (var i = 0; i < RaffleWinners.length; i++) {
                if (RaffleWinners[i] == Account) {
                  showModal(
                    "Congratulations!",
                    `You are one of the winner in this raffle!

Prize: ${RaffleData.WinnerShare} ${RaffleData.TokenName}`
                  );
                  localStorage.setItem(
                    "winner_popup_" + Account + "_" + RaffleId,
                    true
                  );
                  break;
                }
              }
            }
          }
        }
        hideLoader();
      }
    } catch (e) {
      hideLoader();
      console.log("erererere", e);
    }
  }, [Account]);

  async function GetWinners(Raffle_id) {
    //console.log(
    //   "🚀 ~ file: Details.js ~ line 162 ~ GetWinners ~ Raffle_id",
    //   Raffle_id
    // );
  }

  async function UpdateQty(type) {
    if (type === "plus") {
      var newquantity = TotalEntryForAddress + raffleQty + 5;

      if (newquantity <= MaxEntry) {
        if (newquantity <= MaxPerUser) {
          Set_raffleQty(newquantity);
        } else {
          toast.warning(
            "Maximum Entry per address is " + MaxPerUser,
            toasterOption
          );
          newquantity = MaxPerUser;
          Set_raffleQty(newquantity);
        }
      } else {
        toast.warning("Maximum Entry is " + MaxEntry, toasterOption);
        newquantity = MaxEntry;
        Set_raffleQty(newquantity);
      }
    } else {
      var checnewquantity = TotalEntryForAddress + raffleQty - 1;
      if (checnewquantity > 0) {
        if (checnewquantity <= MaxEntry) {
          if (checnewquantity <= MaxPerUser) {
            Set_raffleQty(checnewquantity);
          } else {
            toast.warning(
              "Maximum Entry per address is " + MaxPerUser,
              toasterOption
            );
            checnewquantity = MaxPerUser;
            Set_raffleQty(checnewquantity);
          }
        } else {
          toast.warning("Maximum Entry is " + MaxEntry, toasterOption);
          checnewquantity = MaxEntry;
          Set_raffleQty(checnewquantity);
        }
      } else {
        toast.warning("Entry must be greater than 0", toasterOption);
        Set_raffleQty(1);
      }
    }
  }

  async function updateEntryCount() {
    var TotalEntryForAddress = 0;
    if (Account) {
      TotalEntryForAddress = await RAFFLE_SMART_CONTRACT.methods
        .getEntriesForAddressInRaffle(RaffleId, Account)
        .call();
      TotalEntryForAddress = Number(TotalEntryForAddress.toString());
    }
    set_TotalEntryForAddress(TotalEntryForAddress);
    getMaxBuyableEntries();
  }

  function getMaxBuyableEntries() {
    var remaining = Number(MaxPerUser) - Number(TotalEntryForAddress);
    Set_RemainingBuys(remaining);
    return remaining;
  }

  async function ParticipateRaffle() {
    set_ButtonLoader(true);
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }

    if (!localdata) {
      toast.error("Please connect your wallet", toasterOption);
      set_ButtonLoader(false);
      return false;
    }
    var newquantity = TotalEntryForAddress + raffleQty;
    if (newquantity <= MaxEntry) {
      if (newquantity > MaxPerUser) {
        toast.warning(
          "Maximum Entry per address is " + MaxPerUser,
          toasterOption
        );
        set_ButtonLoader(false);
        return false;
      }
    } else {
      toast.warning("Maximum Entry is " + MaxEntry, toasterOption);
      set_ButtonLoader(false);
      return false;
    }

    try {
      const TOKEN_SMART_CONTRACT = new web3.eth.Contract(
        TOKEN_ABI,
        RaffleData.TokenAdd
      );
      //console.log(
      //   "🚀 ~ file: Details.js ~ line 216 ~ ParticipateRaffle ~ RaffleData && RaffleData.TokenAdd",
      //   RaffleData && RaffleData.TokenAdd
      // );
      //console.log(
      //   "🚀 ~ file: Details.js ~ line 212 ~ ParticipateRaffle ~ TOKEN_SMART_CONTRACT",
      //   TOKEN_SMART_CONTRACT
      // );

      //console.log(
      //   "🚀 ~ file: Details.js ~ line 217 ~ ParticipateRaffle ~ Account",
      //   Account
      // );
      var checkBal = await TOKEN_SMART_CONTRACT.methods
        .balanceOf(Account)
        .call();
      //console.log(
      //   "🚀 ~ file: Details.js ~ ParticipateRaffle ~ checkBal",
      //   checkBal
      // );
      var transferAdjustedTokenAmount = new BigNumber(EntryFee)
        .multipliedBy(100)
        .dividedBy(100 - getTransferFee(RaffleData.TokenName));
      if (
        new BigNumber(checkBal).lt(
          transferAdjustedTokenAmount.multipliedBy(new BigNumber(raffleQty))
        )
      ) {
        toast.error("Not enough tokens!", toasterOption);
        set_ButtonLoader(false);
        return false;
      }

      var checkAllowance = await TOKEN_SMART_CONTRACT.methods
        .allowance(Account, config.RAFFLE_CONTRACT)
        .call();
      //console.log(
      //   "🚀 ~ file: Details.js ~ line 220 ~ ParticipateRaffle ~ checkAllowance",
      //   checkAllowance
      // );
      if (
        new BigNumber(checkAllowance).gte(
          new BigNumber(config.MAX_APPROVAL).dividedBy(2)
        )
      ) {
        CreateEntry();
      } else {
        var estimategas = 0;
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = new BigNumber(gasPrice.toString())
          .multipliedBy(1.3)
          .integerValue()
          .toFixed();

        var ContractCall = await TOKEN_SMART_CONTRACT.methods.approve(
          config.RAFFLE_CONTRACT,
          config.MAX_APPROVAL.toString()
        );

        try {
          estimategas = await TOKEN_SMART_CONTRACT.methods
            .approve(config.RAFFLE_CONTRACT, config.MAX_APPROVAL.toString())
            .estimateGas({ from: Account, gasPrice: gasPrice });
          estimategas = new BigNumber(estimategas.toString())
            .multipliedBy(1.5)
            .integerValue()
            .toFixed();
        } catch (error) {
          Set_FormSubmitLoading(false);
          toast.error(
            "Contract call failed! " + error.toString(),
            toasterOption
          );
          console.log("Contract call failed! " + error.toString());
          return false;
        }

        try {
          await ContractCall.send({
            from: Account,
            gasPrice: gasPrice,
            gasLimit: estimategas,
          }).once("receipt", (Receipt) => {
            //console.log("ReceiptReceiptReceiptReceipt",Receipt)
            if (Receipt) {
              CreateEntry();
            }
          });
        } catch (err) {
          set_ButtonLoader(false);
          console.log("Error for Token Mint", err);
        }
      }
    } catch (Errere) {
      console.log(
        "🚀 ~ file: Details.js ~ line 249 ~ ParticipateRaffle ~ Errere",
        Errere
      );
    }

    async function CreateEntry() {
      // set_ButtonLoader(true);
      var localdata = false;
      try {
        localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
      } catch (error) {
        localdata = false;
      }
      if (!localdata) {
        toast.error("Please connect your wallet", toasterOption);
        set_ButtonLoader(false);
        return false;
      }
      var mintCall = null;
      var receipt = null;
      var handle = null;
      try {
        var ContractCall = null;
        var estimategas = 0;
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = new BigNumber(gasPrice.toString())
          .multipliedBy(1.3)
          .integerValue()
          .toFixed();
        ContractCall = await RAFFLE_SMART_CONTRACT.methods.enterRaffle(
          RaffleId,
          raffleQty
        );

        try {
          estimategas = await RAFFLE_SMART_CONTRACT.methods
            .enterRaffle(RaffleId, raffleQty)
            .estimateGas({ from: Account, gasPrice: gasPrice });
          estimategas = new BigNumber(estimategas.toString())
            .multipliedBy(1.5)
            .integerValue()
            .toFixed();
        } catch (error) {
          Set_FormSubmitLoading(false);
          toast.error(
            "Contract call failed! " + error.toString(),
            toasterOption
          );
          console.log("Contract call failed! " + error.toString());
          return false;
        }

        await ContractCall.send({
          from: Account,
          gasPrice: gasPrice,
          gasLimit: estimategas,
        }).on("receipt", (Receipt) => {
          mintCall = Receipt;
          if (mintCall) {
            updateEntryCount();
            set_ButtonLoader(false);
            toast.success("Entry Succesful", toasterOption);
            setTimeout(function () {
              RefreshPage();
            }, 6500);
            showModal("Bought Entry", "Entered raffle successfully!");
          }
        });
      } catch (err) {
        set_ButtonLoader(false);
        console.log("ererer", err);
      }
    }
  }

  function RefreshPage() {
    window.location.reload(false);
  }

  async function CloseAndRefund() {
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (localdata) {
      if (!RaffleClosed) {
        try {
          Set_FormSubmitLoading(true);
          var ContractCall = null;
          var estimategas = 0;
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = new BigNumber(gasPrice.toString())
            .multipliedBy(1.3)
            .integerValue()
            .toFixed();
          ContractCall = await RAFFLE_SMART_CONTRACT.methods.closeRaffleAndRefund(
            RaffleId
          );

          try {
            estimategas = await RAFFLE_SMART_CONTRACT.methods
              .closeRaffleAndRefund(RaffleId)
              .estimateGas({ from: Account, gasPrice: gasPrice });
            estimategas = new BigNumber(estimategas.toString())
              .multipliedBy(1.5)
              .integerValue()
              .toFixed();
          } catch (error) {
            Set_FormSubmitLoading(false);
            toast.error(
              "Contract call failed! " + error.toString(),
              toasterOption
            );
            console.log("Contract call failed! " + error.toString());
            return false;
          }

          await ContractCall.send({
            from: Account,
            gasPrice: gasPrice,
            gasLimit: estimategas,
          }).on("receipt", (receipt) => {
            //console.log("receipt", receipt);
            if (receipt) {
              Set_FormSubmitLoading(false);
              toast.success("Close and refund successfull", toasterOption);
              setTimeout(function () {
                RefreshPage();
              }, 3500);
              showModal(
                "Close and Refund",
                "Closed and refunded raffle successfully!"
              );
            } else {
              toast.error("Operation failed! Please try again", toasterOption);
              Set_FormSubmitLoading(false);
            }
          });
        } catch (err) {
          toast.error("Operation cancelled", toasterOption);
          Set_FormSubmitLoading(false);
        }
      } else {
        toast.error("Raffle is already closed", toasterOption);
        Set_FormSubmitLoading(false);
      }
    } else {
      Set_FormSubmitLoading(false);

      toast.error("Please connect your wallet", toasterOption);
    }
  }

  async function DeleteAndRefund() {
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (localdata) {
      if (!RaffleDeleted) {
        try {
          Set_FormSubmitLoading(true);
          var ContractCall = null;
          var estimategas = 0;
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = new BigNumber(gasPrice.toString())
            .multipliedBy(1.3)
            .integerValue()
            .toFixed();
          ContractCall = await RAFFLE_SMART_CONTRACT.methods.deleteRaffleAndRefund(
            RaffleId
          );

          try {
            estimategas = await RAFFLE_SMART_CONTRACT.methods
              .deleteRaffleAndRefund(RaffleId)
              .estimateGas({ from: Account, gasPrice: gasPrice });
            estimategas = new BigNumber(estimategas.toString())
              .multipliedBy(1.5)
              .integerValue()
              .toFixed();
          } catch (error) {
            Set_FormSubmitLoading(false);
            toast.error(
              "Contract call failed! " + error.toString(),
              toasterOption
            );
            console.log("Contract call failed! " + error.toString());
            return false;
          }

          await ContractCall.send({
            from: Account,
            gasPrice: gasPrice,
            gasLimit: estimategas,
          }).on("receipt", (receipt) => {
            //console.log("receipt", receipt);
            if (receipt) {
              Set_FormSubmitLoading(false);
              toast.success("Delete and refund successfull", toasterOption);
              setTimeout(function () {
                RefreshPage();
              }, 3500);
              showModal(
                "Delete and Refund",
                "Deleted and refunded raffle successfully!"
              );
            } else {
              Set_FormSubmitLoading(false);
              toast.error("Operation failed! Please try again", toasterOption);
            }
          });
        } catch (err) {
          toast.error("Operation cancelled! " + err.toString(), toasterOption);
          Set_FormSubmitLoading(false);
        }
      } else {
        toast.error("Raffle is already deleted", toasterOption);
        Set_FormSubmitLoading(false);
      }
    } else {
      Set_FormSubmitLoading(false);

      toast.error("Please connect your wallet", toasterOption);
    }
  }

  async function payWinners() {
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (localdata) {
      if (isComplete) {
        toast.error("Winners already paid", toasterOption);
        return false;
      }

      if (RaffleClosed) {
        try {
          Set_FormSubmitLoading(true);
          var mintCall = null;

          var ContractCall = null;
          var estimategas = 0;
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = new BigNumber(gasPrice.toString())
            .multipliedBy(1.3)
            .integerValue()
            .toFixed();
          ContractCall = await RAFFLE_SMART_CONTRACT.methods.payWinners(
            RaffleId
          );

          try {
            estimategas = await RAFFLE_SMART_CONTRACT.methods
              .payWinners(RaffleId)
              .estimateGas({ from: Account, gasPrice: gasPrice });
            estimategas = new BigNumber(estimategas.toString())
              .multipliedBy(1.5)
              .integerValue()
              .toFixed();
          } catch (error) {
            Set_FormSubmitLoading(false);
            toast.error(
              "Contract call failed! " + error.toString(),
              toasterOption
            );
            console.log("Contract call failed! " + error.toString());
            return false;
          }

          await ContractCall.send({
            from: Account,
            gasPrice: gasPrice,
            gasLimit: estimategas,
          }).on("receipt", (receipt) => {
            //console.log("receipt", receipt);
            if (receipt) {
              toast.success("Winners paid successfully", toasterOption);
              setTimeout(function () {
                RefreshPage();
              }, 3500);
              showModal(
                "Raffle Winners Paid",
                "All Raffle winners are paid successfully!"
              );
              Set_FormSubmitLoading(false);
            } else {
              Set_FormSubmitLoading(false);
              toast.error("Operation failed! Please try again", toasterOption);
            }
          });
        } catch (err) {
          Set_FormSubmitLoading(false);
          toast.error("Operation failed! " + err.toString(), toasterOption);
        }
      } else {
        toast.error("Raffle is not drawn yet!", toasterOption);
      }
    } else {
      toast.error("Please connect your wallet", toasterOption);
    }
  }
  async function DrawWinners() {
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (localdata) {
      if (RaffleWinners.length > 0) {
        toast.error("Winners already drawn", toasterOption);
        return false;
      }

      if (Enddate < new Date() || Enddate === 0) {
        try {
          Set_FormSubmitLoading(true);
          var mintCall = null;

          var ContractCall = null;
          var estimategas = 0;
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = new BigNumber(gasPrice.toString())
            .multipliedBy(1.3)
            .integerValue()
            .toFixed();
          ContractCall = await RAFFLE_SMART_CONTRACT.methods.drawWinners(
            RaffleId
          );

          try {
            estimategas = await RAFFLE_SMART_CONTRACT.methods
              .drawWinners(RaffleId)
              .estimateGas({ from: Account, gasPrice: gasPrice });
            estimategas = new BigNumber(estimategas.toString())
              .multipliedBy(1.5)
              .integerValue()
              .toFixed();
          } catch (error) {
            Set_FormSubmitLoading(false);
            toast.error(
              "Contract call failed! " + error.toString(),
              toasterOption
            );
            console.log("Contract call failed! " + error.toString());
            return false;
          }

          await ContractCall.send({
            from: Account,
            gasPrice: gasPrice,
            gasLimit: estimategas,
          }).on("receipt", (receipt) => {
            //console.log("receipt", receipt);
            if (receipt) {
              toast.success("Winners drawn successfully", toasterOption);
              setTimeout(function () {
                RefreshPage();
              }, 3500);
              showModal(
                "Raffle Drawn",
                "Raffle winners are drawn successfully!"
              );
              Set_FormSubmitLoading(false);
            } else {
              Set_FormSubmitLoading(false);
              toast.error("Operation failed! Please try again", toasterOption);
            }
          });
        } catch (err) {
          Set_FormSubmitLoading(false);
          toast.error("Operation failed! " + err.toString(), toasterOption);
        }
      } else {
        toast.error("Raffle is not over yet!", toasterOption);
      }
    } else {
      toast.error("Please connect your wallet", toasterOption);
    }
  }

  const [show, setShow] = useState(false);
  const [modalContent, set_ModalContent] = useState("");
  const [modalTitle, set_ModalTitle] = useState("");

  const handleClose = () => setShow(false);
  async function showModal(title, content) {
    set_ModalContent(content);
    set_ModalTitle(title);
    setShow(true);
  }
  async function goHome() {
    window.location.href = "/home";
  }

  return (
    <div className="App position-relative mt-nav">
      {Loading &&
      <Loader />}
      <div className="h-100vh bg-homebanner position-relative">
        {/* <HomeBanner /> */}

        <div className="main-center container">
          <Breadcrumb
            style={{
              position: "relative",
              marginBottom: "2rem",
              fontSize: "1rem",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
            separator=">"
          >
            <Breadcrumb.Item>
              <Link to="/">Explore</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{RaffleData && RaffleData.Title}</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row mb-5">
            <div className="col-12 col-md-5 col-xl-4 d-flex">
              <div className="c-card-hoverable card mb-md-0">
                {TickerState &&
                  TickerState === constants.TICKER_STATE_DRAWING_SOON && (
                    <div className="wrap">
                      <span className="ribbon bg-drawing-soon">
                        Drawing Soon
                        <div>
                          <div className="glow">&nbsp;</div>
                        </div>
                      </span>
                    </div>
                  )}
                {TickerState &&
                  TickerState === constants.TICKER_STATE_CLOSING_SOON && (
                    <div className="wrap">
                      <span className="ribbon bg-closes-soon">
                        Closing Soon
                        <div>
                          <div className="glow">&nbsp;</div>
                        </div>
                      </span>
                    </div>
                  )}
                {TickerState && TickerState === constants.TICKER_STATE_CLOSED && (
                  <div className="wrap">
                    <span className="ribbon bg-closed">
                      Closed
                      <div>
                        <div className="glow">&nbsp;</div>
                      </div>
                    </span>
                  </div>
                )}
                {TickerState && TickerState === constants.TICKER_STATE_OPEN && (
                  <div className="wrap">
                    <span className="ribbon bg-open">
                      Open
                      <div>
                        <div className="glow">&nbsp;</div>
                      </div>
                    </span>
                  </div>
                )}
                {TickerState &&
                  TickerState === constants.TICKER_STATE_STARTING_SOON && (
                    <div className="wrap">
                      <span className="ribbon bg-starting-soon">
                        Starting Soon
                        <div>
                          <div className="glow">&nbsp;</div>
                        </div>
                      </span>
                    </div>
                  )}
                <div className="card-cover fl-cc pt-5">
                  {RaffleData && RaffleData.Image && RaffleData.Image !== "" ? (
                    <img
                      className="img-fluid"
                      loading="lazy"
                      src={config.IPFS_URL + RaffleData.Image}
                      alt="#"
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      loading="lazy"
                      src={require("../assets/images/aidilogo.png")}
                      alt="#"
                    />
                  )}
                </div>
                <div className="divider-horizontal" role="separator" />
                <div className="card-body  text-center">
                  <h3 className="typography">
                    {RaffleData && RaffleData.Title}
                  </h3>
                  <h3 className="font-weight-bold text-primary mt-4">
                  {Number(TotalEntryForAddress) > 0 ? TotalEntryForAddress == 1
                          ? `Bought ${TotalEntryForAddress} entry`
                          : `Bought ${TotalEntryForAddress} entries`
                        :``}
                    { }
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-xl-8">
              <div className="c-card-hoverable card right-card h-100">
                <div className="card-body d-flex align-content-start flex-column">
                  <span className="c-card-header">
                    {RaffleData && RaffleData.SubTitle}
                  </span>
                  <h3 className="typography">
                    {RaffleData && RaffleData.Title}
                  </h3>
                  <h5 className="dercription-scroll">
                    {RaffleData && RaffleData.Description}
                  </h5>
                  <div className="row mt-lg-auto">
                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Ticket Price
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Raffle ticket price for one entry"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.EntryFee}{" "}
                            {RaffleData && RaffleData.TokenName}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Prize Percentage
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Percentage of total collected raffle entry funds that will be split equally among all the winners"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.RewardPerc}%
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Prize Per Winner
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Current accumulated total prize pool divided by number of winners"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.WinnerShare}{" "}
                            {RaffleData && RaffleData.TokenName}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Minimum Entries
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Minimum number of entries required to draw the raffle"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.Min_entry}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Maximum Entries
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Maximum number of entries allowed in the raffle"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.Max_entry}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              Total Entries
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Total entries registered for this raffle"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.TotalEntryLength}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              {RaffleData && RaffleData.StartDateTitle}
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Start status of the raffle"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.StartDateStr}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 mb-3">
                      <div className="statistic">
                        <div className="statistic-title">
                          <h5 className="typography mb-0">
                            <span className="">
                              {RaffleData && RaffleData.EnddateTitle}
                              <span
                                className="pointer-cursor ms-2"
                                data-toggle="tooltip"
                                title="Close status of the raffle"
                              >
                                <i className="far fa-question-circle text-muted" />
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className="statistic-content">
                          <span className="statistic-content-value">
                            {RaffleData && RaffleData.EnddateStr}
                          </span>
                        </div>
                      </div>
                    </div>
                    {RaffleData && RaffleData.Enddate !== 0 && (
                      <div className="col-6 col-lg-4 mb-3">
                        <div className="statistic">
                          <div className="statistic-title">
                            <h5 className="typography mb-0">
                              <span className="">Closes in</span>
                            </h5>
                          </div>
                          <div className="statistic-content">
                            <span className="statistic-content-value">
                              {RaffleData &&
                                RaffleData.EnddateRemain !== 0 &&
                                SecondsToDay(RaffleData.EnddateRemain)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="progress-line">
                                        <div className="progress-outer">
                                          <div className="progress-inner">
                                            <div className="progress-bg" />
                                          </div>
                                        </div>
                                      </div> */}

                  {/* {Account!==""&& RaffleData && RaffleData.} */}
                  {!RaffleClosed && !MaximumBought && !IMaximumBought && (
                    <div className="mt-5 ">
                      <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="c-border-radius overflow-hidden">
                            <button
                              className="c-mint-no-btn"
                              tabIndex="0"
                              type="button"
                              onClick={() => UpdateQty("minus")}
                            >
                              -1
                              <span className="MuiTouchRipple-root css-w0pj6f" />
                            </button>
                            <div className="c-mint-no-field">
                              <div className="">
                                <input
                                  aria-invalid="false"
                                  id="mintAmountInput"
                                  type="number"
                                  disabled="true"
                                  value={raffleQty}
                                  className="MuiInput-input MuiInputBase-input css-xdqqn2"
                                />
                              </div>
                            </div>
                            <button
                              className="c-mint-no-btn"
                              tabIndex="0"
                              type="button"
                              onClick={() => UpdateQty("plus")}
                            >
                              +5
                              <span className="MuiTouchRipple-root css-w0pj6f" />
                            </button>
                          </div>
                        </div>

                        <button
                          className="btn btn-primary c-btn ms-2 ms-lg-4"
                          disabled={ButtonLoader}
                          onClick={() => ParticipateRaffle()}
                        >
                          {ButtonLoader && (
                            <i className="fa fa-spinner fa-spin" />
                          )}
                          Buy Entries
                        </button>
                      </div>
                      <Form.Text className="text-muted d-flex justify-content-end">
                        {RemainingBuys == 1
                          ? `You can buy up to ${RemainingBuys} entry`
                          : `You can buy up to ${RemainingBuys} entries`}
                      </Form.Text>
                    </div>
                  )}
                  {!RaffleClosed && MaximumBought && (
                    <div className="d-flex justify-content-end mt-5 ">
                      <button
                        className="btn btn-primary c-btn ms-2 ms-lg-4"
                        disabled={MaximumBought}
                        onClick={() => ParticipateRaffle()}
                      >
                        {ButtonLoader && (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                        Raffle Filled
                      </button>
                    </div>
                  )}
                  {!RaffleClosed && !MaximumBought && IMaximumBought && (
                    <div className="d-flex justify-content-end mt-5 ">
                      <button
                        className="btn btn-primary c-btn ms-2 ms-lg-4"
                        disabled={IMaximumBought}
                        onClick={() => ParticipateRaffle()}
                      >
                        {ButtonLoader && (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                        {TotalEntryForAddress == 1
                          ? `Bought Maximum Entry (${TotalEntryForAddress})`
                          : `Bought Maximum Entries (${TotalEntryForAddress})`}
                      </button>
                    </div>
                  )}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    contentClassName="c-card-hoverable"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <h4 className="fw-bold mb-0">{modalTitle}</h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalContent}</Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="c-btn"
                        variant="primary"
                        onClick={goHome}
                      >
                        Home
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            {(Enddate < new Date() || Enddate === 0) &&
            (Account === (RaffleData && RaffleData.Raffle_Creator) ||
              Account === config.raffleContractOwner) &&
            !RaffleClosed &&
            !RaffleDeleted &&
            RaffleWinners.length === 0 ? (
              <div className="col-lg-2 col-sm-4 col-6 mb-4 ">
                <button
                  className="btn btn-primary btn-block c-btn ms-lg-2"
                  onClick={CloseAndRefund}
                  disabled={FormSubmitLoading}
                >
                  {FormSubmitLoading && (
                    <i
                      class="fa fa-spinner fa-spin mr-3 spinner_icon"
                      aria-hidden="true"
                      id="circle1"
                    ></i>
                  )}
                  Refund Raffle
                </button>
              </div>
            ) : (
              ""
            )}

            {(Account === (RaffleData && RaffleData.Raffle_Creator) ||
              Account === config.raffleContractOwner) &&
            !RaffleDeleted &&
            RaffleWinners.length === 0 ? (
              <div className="col-lg-2 col-sm-4 col-6 mb-4 ">
                <button
                  className="btn btn-primary btn-block c-btn ms-lg-2"
                  onClick={DeleteAndRefund}
                  disabled={FormSubmitLoading}
                >
                  {FormSubmitLoading && (
                    <i
                      class="fa fa-spinner fa-spin mr-3 spinner_icon"
                      aria-hidden="true"
                      id="circle1"
                    ></i>
                  )}
                  Delete Raffle
                </button>
              </div>
            ) : (
              ""
            )}

            {(Enddate < new Date() || Enddate === 0) &&
            (Account === (RaffleData && RaffleData.Raffle_Creator) ||
              IsUserParticipating) &&
            !RaffleClosed &&
            RaffleWinners.length === 0 ? (
              <div className="col-lg-2 col-sm-4 col-6 mb-4 ">
                <button
                  className="btn btn-primary btn-block c-btn ms-lg-2"
                  onClick={DrawWinners}
                  disabled={FormSubmitLoading}
                >
                  {FormSubmitLoading && (
                    <i
                      class="fa fa-spinner fa-spin mr-3 spinner_icon"
                      aria-hidden="true"
                      id="circle1"
                    ></i>
                  )}
                  Draw Winners
                </button>
              </div>
            ) : (
              ""
            )}
            {(Account === (RaffleData && RaffleData.Raffle_Creator) ||
              Account === config.raffleContractOwner) &&
            RaffleClosed &&
            !isComplete &&
            RaffleWinners.length > 0 ? (
              <div className="col-lg-2 col-sm-4 col-6 mb-4 ">
                <button
                  className="btn btn-primary btn-block c-btn ms-lg-2"
                  onClick={payWinners}
                  disabled={FormSubmitLoading}
                >
                  {FormSubmitLoading && (
                    <i
                      class="fa fa-spinner fa-spin mr-3 spinner_icon"
                      aria-hidden="true"
                      id="circle1"
                    ></i>
                  )}
                  Pay Winners
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row mb-4">
                <div className="col-12">
                  <h2 className="font-weight-bold text-center my-5">
                    Raffle Results
                  </h2>
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <div className="c-card-hoverable card mb-0 h-100">
                    <div className="card-cover">
                      <div className="counter d-flex flex-column bs-11">
                        <div className="fl-te-c d-flex justify-content-between align-items-center">
                          <div className="fl-y-l pr-1 d-flex align-items-start flex-column">
                            <h5 className="pr-5 mb-5">Total Prize</h5>
                            <h1 className="font-weight-bold text-primary mb-0">
                              {TotalPrize} {RaffleData && RaffleData.TokenName}
                            </h1>
                          </div>
                          <i className="fa fa-4x fa-trophy text-primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <div className="c-card-hoverable card mb-0 h-100">
                    <div className="card-cover">
                      <div className="counter d-flex flex-column bs-11">
                        <div className="fl-te-c d-flex justify-content-between align-items-center">
                          <div className="fl-y-l pr-1 d-flex align-items-start flex-column">
                            <h5 className="pr-5 mb-5">Total Winners</h5>
                            <h1 className="font-weight-bold text-primary mb-0">
                              {RaffleData && RaffleData.NoOfWinners}
                            </h1>
                          </div>
                          <i className="fa fa-4x fa-gift text-primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {RaffleWinners.length > 0 && (
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="table-section">
                      <table id="table" className="w-100">
                        <tr className="header-row">
                          <th className="header-item items">Winner</th>
                          <th className="header-item items">Prize</th>
                        </tr>

                        {RaffleWinners.length > 0
                          ? RaffleWinners.map((item, i) => {
                              var peralloc = TotalPrize / RaffleWinners.length;
                              return (
                                <tr className="table-rows">
                                  <td className="items">{item}</td>
                                  <td className="items">
                                    {peralloc}{" "}
                                    {RaffleData && RaffleData.TokenName}
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        {/* <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr>
                      <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr>
                      <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr>
                      <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr>
                      <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr>
                      <tr className="table-rows">
                        <td className="items">
                          0xb358b0e5A8943029e66175830D85198fE6cC93f6
                        </td>
                        <td className="items">6.6 BUSD</td>
                      </tr> */}
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <a href="javascript:" onClick={topFunction} id="return-to-top">
            <i className="icofont icofont-arrow-up" />
          </a>
        </div>
      </div>
    </div>
  );
}
