
import React, { useState, useEffect } from "react";
import { BigNumber} from 'bignumber.js';
import {ethers} from 'ethers';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HomeBanner from "./components/HomeBanner";
import SimpleReactValidator from "simple-react-validator";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Hash from 'ipfs-only-hash';
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import "react-datepicker/dist/react-datepicker.css";
import CardItem from "./components/CardItem";

import { toast } from "react-toastify";
import config, {getDecimals, getTransferFee}  from "../lib/config";

import Web3 from "web3";
import "@metamask/legacy-web3";
import moment from "moment";

import RAFFLE_ABI from "../Abi/Raffle.json";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
import { getReceipt } from "../actions/web3actions";
import { parse } from "date-fns/esm";
import ipfs from "../actions/ipfs";
import Select from "react-select";
import { SecondsToDay } from "../actions/dateFormat";


toast.configure();
let toasterOption = config.toasterOption;
var RAFFLE_ADDRESS = config.RAFFLE_CONTRACT_ETH;

var web3 = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? new Web3(config.INFURA_URL): new Web3(config.INFURA_URL_POLY) : new Web3(config.INFURA_URL);

var RAFFLE_SMART_CONTRACT = new web3.eth.Contract(RAFFLE_ABI, RAFFLE_ADDRESS);

window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  let toTop = document.getElementById("return-to-top");
  if (toTop != null && toTop.style != null && toTop.style.display != null) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      toTop.style.display = "block";
    } else {
      toTop.style.display = "none";
    }
  }
}

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

function Create() {
  var date = new Date();
  var tomorrow = date.setDate(date.getDate() + 1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(tomorrow);
  var { address, isConnected } = useAccount();
  const [value, setValue] = useState(new Date());
  const [ValidateError, setValidateError] = useState({});
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState("");

  const [Raffle_title, Set_Raffle_title] = useState("");
  const [Raffle_subtitle, Set_Raffle_subtitle] = useState("");
  const [Raffle_desc, Set_Raffle_desc] = useState("");

  const [Raffle_noOfWinners, Set_Raffle_noOfWinners] = useState("");
  const [Raffle_RewardPerc, Set_Raffle_RewardPerc] = useState("");
  const [Raffle_MaxPeruser, Set_Raffle_MaxPeruser] = useState("");
  const [Raffle_MaxEntry, Set_Raffle_MaxEntry] = useState("");
  const [Raffle_MinEntry, Set_Raffle_MinEntry] = useState("");
  const [Raffle_EntFee, Set_Raffle_EntFee] = useState("");
  const [Raffle_Token, Set_Raffle_Token] = useState({ label: "" });
  const [Raffle_Token_name, Set_Raffle_Token_name] = useState("");

  // const [TokenCategory, setTokenCategory] = React.useState({ label: "" });

  const [startdisabled, set_startdisabled] = useState(false);
  const [enddisabled, set_enddisabled] = useState(false);

  const [TokenFile, setTokenFile] = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [ImageBuffer, set_ImageBuffer] = useState("");

  const [Account, set_Accounts] = useState("");
  const [ETHBalance, set_ETHBalance] = useState(0);
  const [CreateFee, set_CreateFee] = useState(0);
  const [AidiUtilityFee, set_AidiUtilityFee] = useState(0);
  var [Network, set_Network] = React.useState("Ethereum");

  if(config.currentNetwork == "Ethereum")
  {
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT_ETH
    );

  }else{
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT
    );
  }
  if (config.provider)
  {
    web3 = new Web3(config.provider);
  }

  async function CheckWalletConnected() {

    if (config.provider)
    {
      web3 = await new Web3(config.provider);
      if (web3) {
        const chainId = await web3.eth.getChainId();
        if (chainId.toString() == "1") {
          config.currentNetwork = "Ethereum";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT_ETH
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
        }else if (chainId.toString() == "137") {
          config.currentNetwork = "Polygon";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
        }
      }
    }
  }

  useEffect(async () => {
    await  CheckWalletConnected(); 
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (!localdata) {
      toast.error("Please Connect With Wallet to Continue...");

      setTimeout(() => {
        window.location.href = "/home";
      }, 1000);
    }

    var createfee = await RAFFLE_SMART_CONTRACT.methods
      .raffleCreateFee()
      .call();
    set_CreateFee(createfee);

    var aidiFee = await RAFFLE_SMART_CONTRACT.methods
      .aidiUtilityFee()
      .call();
      set_AidiUtilityFee(aidiFee);
  }, [localStorage.getItem, config.provider],
  [address, isConnected]);

  const DateSelector = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      if (e.target.name === "startdate") {
        setStartDate(0);
        set_startdisabled(!startdisabled);
      } else if (e.target.name === "enddate") {

        if(endDate!==0){
          setEndDate(0);
          
        }else{
          setEndDate(new Date());
        }
        set_enddisabled(!enddisabled);
      }
    }
  };

  const DropSelect = (filter) => {
    Set_Raffle_Token(filter.value);
    var tokenadd= filter.value.split("-")
    Set_Raffle_Token_name(tokenadd[0])
  };
  const inputChange = (e) => {
    Set_FormSubmitLoading("done");
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "Raffle_title":
          Set_Raffle_title(value);
          break;
        case "Raffle_subtitle":
          Set_Raffle_subtitle(value);
          break;
        case "Raffle_desc":
          Set_Raffle_desc(value);
          break;
        case "Raffle_noOfWinners":
          Set_Raffle_noOfWinners(value);
          break;
        case "Raffle_RewardPerc":
          Set_Raffle_RewardPerc(value);
          break;
        case "Raffle_MaxPeruser":
          Set_Raffle_MaxPeruser(value);
          break;
        case "Raffle_MaxEntry":
          Set_Raffle_MaxEntry(value);
          break;
        case "Raffle_MinEntry":
          Set_Raffle_MinEntry(value);
          break;
        case "Raffle_EntFee":
          Set_Raffle_EntFee(value);
          break;
        // case "Raffle_Token":
        //   Set_Raffle_Token(value);
        //   break;
        default:
      }
    }
  };

  const selectFileChange = async (e) => {
    let imageFormat = /\.(png|gif|jpeg|webp|PNG|GIF|WEBP|JPEG)$/;
    if (e.target.files[0].size > 3000000) {
      setValidateError({ TokenFilePreUrl: "Image must be lesser than 3 MB" });
    } else if (!imageFormat.test(e.target.files[0].name)) {
      setValidateError({
        TokenFilePreUrl: "Selected image must be PNG,GIF,WEBP",
      });
    } else {
      if (e.target && e.target.files) {
        setValidateError("");
        var reader = new FileReader();

        var file = e.target.files[0];
        setTokenFile(file);
        // set_ImageBuffer(file);

        var url = reader.readAsDataURL(file);

        reader.onloadend = async function (e) {
          if (reader.result) {
            setTokenFilePreReader(reader.result);
            //  var  bufferfile = await Buffer(reader.result)

            //  set_ImageBuffer(bufferfile);
          }
        }.bind(this);

        let newreader = new window.FileReader();
        newreader.readAsArrayBuffer(file);
        newreader.onloadend = () => convertToBuffer(newreader);

        setTokenFilePreUrl(e.target.value);
      }
    }
  };

  const convertToBuffer = async (reader) => {
    //file is converted to a buffer to prepare for uploading to IPFS
    const buffer = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax
    // this.setState({buffer});
    set_ImageBuffer(buffer);
  };

  function isNumeric(str) {
    if (typeof str != "string") return false 
    return !isNaN(str) && !isNaN(parseFloat(str)) 
  }

  async function CreateRaffleValidation() {
    var ValidateError = {};

    var noOfWinners = new BigNumber(0);
    var rewardPerc = new BigNumber(0);
    var maxPeruser = new BigNumber(0);
    var maxEntry = new BigNumber(0);
    var minEntry = new BigNumber(0);
    var entryFee = new BigNumber(0);

    if (Raffle_title === "") {
      ValidateError.Raffle_title = '"Title" cannot be empty';
    }
    if (Raffle_subtitle === "") {
      ValidateError.Raffle_subtitle = '"Sub Title" cannot be empty';
    }
    if (Raffle_desc === "") {
      ValidateError.Raffle_desc = '"Description" cannot be empty';
    }

    if (Raffle_noOfWinners === "") {
      ValidateError.Raffle_noOfWinners =
        '"Number of Winners" cannot be empty';
    }else{
      if(!isNumeric(Raffle_noOfWinners.toString()))
      {
        ValidateError.Raffle_noOfWinners =
        '"Number of Winners" should be a number';
      }else{
        noOfWinners = new BigNumber(Raffle_noOfWinners);
        if(noOfWinners.eq(0))
        {
          ValidateError.Raffle_noOfWinners =
        '"Number of Winners" should be > 0';
        }
      }
    }

    if (Raffle_RewardPerc === "") {
      ValidateError.Raffle_RewardPerc =
        '"Reward Percentage" cannot be empty';
    }else{
      if(!isNumeric(Raffle_RewardPerc.toString()))
      {
        ValidateError.Raffle_RewardPerc =
        '"Reward Percentage" should be a number';
      }else{
        rewardPerc = new BigNumber(Raffle_RewardPerc);
        if(rewardPerc.eq(0))
        {
          ValidateError.Raffle_RewardPerc =
        '"Reward Percentage" should be > 0';
        }
      }
    }

    if (Raffle_MaxPeruser === "") {
      ValidateError.Raffle_MaxPeruser =
        '"Maximum Number of Entries per Address" cannot be empty';
    }else{
      if(!isNumeric(Raffle_MaxPeruser.toString()))
      {
        ValidateError.Raffle_MaxPeruser =
        '"Maximum Number of Entries per Address" should be a number';
      }else{
        maxPeruser = new BigNumber(Raffle_MaxPeruser);
        if(maxPeruser.eq(0))
        {
          ValidateError.Raffle_MaxPeruser =
        '"Maximum Number of Entries per Address" should be > 0';
        }
      }
    }

    if (Raffle_MaxEntry === "") {
      ValidateError.Raffle_MaxEntry =
        '"Maximum Number of Entries" cannot be empty';
    }else{
      if(!isNumeric(Raffle_MaxEntry.toString()))
      {
        ValidateError.Raffle_MaxEntry =
        '"Maximum Number of Entries" should be a number';
      }else{
        maxEntry = new BigNumber(Raffle_MaxEntry);
        if(maxEntry.eq(0))
        {
          ValidateError.Raffle_MaxEntry =
        '"Maximum Number of Entries" should be > 0';
        }
      }
    }

    if (Raffle_MinEntry === "") {
      ValidateError.Raffle_MinEntry =
        '"Minimum Number of Entries" cannot be empty';
    }else{
      if(!isNumeric(Raffle_MinEntry.toString()))
      {
        ValidateError.Raffle_MinEntry =
        '"Minimum Number of Entries" should be a number';
      }else{
        minEntry = new BigNumber(Raffle_MinEntry);
        if(minEntry.eq(0))
        {
          ValidateError.Raffle_MinEntry =
        '"Minimum Number of Entries" should be > 0';
        }
      }
    }

    if (Raffle_EntFee === "") {
      ValidateError.Raffle_EntFee = 
      '"Entry Fees" cannot be empty';
    }else{
      if(!isNumeric(Raffle_EntFee.toString()))
      {
        ValidateError.Raffle_EntFee =
        '"Entry Fees" should be a number';
      }else{
        entryFee = new BigNumber(Raffle_EntFee);
        if(entryFee.eq(0))
        {
          ValidateError.Raffle_EntFee =
        '"Entry Fees" should be > 0';
        }
      }
    }

    if (Raffle_Token === "") {
      ValidateError.Raffle_Token = '"Entry Token Contract Address" cannot be empty';
    }

    if(noOfWinners.gt(0) && rewardPerc.gt(0) 
      && maxPeruser.gt(0) && maxEntry.gt(0) 
      && minEntry.gt(0))
    {
        var maxReward = Number(100 - Number(AidiUtilityFee));
        if(rewardPerc.gt(maxReward))
        {
          ValidateError.Raffle_RewardPerc =
          `"Reward Percentage" should be ≤ ${maxReward}`;
        }

        if(noOfWinners.gt(50))
        {
          ValidateError.Raffle_noOfWinners =
          '"Number of Winners" should be ≤ 50';
        }else{
          if(noOfWinners.gt(maxEntry))
          {
            ValidateError.Raffle_noOfWinners =
            `"Number of Winners" should be ≤ "Maximum Number of Entries" (${maxEntry.integerValue().toString()})`;
          }else if(noOfWinners.lt(minEntry))
          {
            ValidateError.Raffle_noOfWinners =
            `"Number of Winners" should be ≥ "Minimum Number of Entries" (${minEntry.integerValue().toString()})`;
          }
        }

        if(maxPeruser.gt(maxEntry))
        {
          ValidateError.Raffle_MaxPeruser =
          `"Maximum Number of Entries per Address" should be ≤ "Maximum Number of Entries" (${maxEntry.integerValue().toString()})`;
        }

        if(minEntry.gt(maxEntry))
        {
          ValidateError.Raffle_MinEntry =
          `"Minimum Number of Entries" should be ≤ "Maximum Number of Entries" (${maxEntry.integerValue().toString()})`;
        }
    }

    var starttimestamp = 0;
    var endtimestamp = 0;
    var nowtimestamp =  moment(moment(new Date()).format("YYYY-MM-DD HH:mm:ss")).format("X");
    if (!startdisabled) {
      var localTime = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      starttimestamp = moment(localTime).format("X");
    }
    if (!enddisabled) {
      var endtimsss = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
      endtimestamp = moment(endtimsss).format("X");
    }

    if(endtimestamp > 0)
    {
      if(starttimestamp > endtimestamp)
      {
        ValidateError.Raffle_StartDate = "Start time should be before End Time";
      }
      if(nowtimestamp > endtimestamp)
      {
        ValidateError.Raffle_EndDate = "End Time should be in the future";
      }
    }

    setValidateError(ValidateError);
    return ValidateError;
  }
  async function CreateItem() {
  
    var starttimestamp = 0;
    var endtimestamp = 0;
    Set_FormSubmitLoading("processing");
    var errors = await CreateRaffleValidation();
    var errorsSize = Object.keys(errors).length;
    if (errorsSize !== 0) {
      toast.error(
        "Form validation error. Fix all errors and submit again",
        toasterOption
      );
      Set_FormSubmitLoading("done");

      return false;
    }

    if (!startdisabled) {
      var localTime = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      starttimestamp = moment(localTime).format("X");
    }
    if (!enddisabled) {
      var endtimsss = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

      endtimestamp = moment(endtimsss).format("X");
    }

    let  ipfsimagehash =  ""
    if(TokenFile!==""){
      const imagefile = ImageBuffer;
      const filesAdd = await ipfs.add(imagefile);
      ipfsimagehash = await Hash.of(imagefile);
    }
 
    const metaraffle = {
      Raffle_Token: Raffle_Token,
      Raffle_EntFee: Raffle_EntFee,
      Raffle_MinEntry: Raffle_MinEntry,
      Raffle_MaxEntry: Raffle_MaxEntry,
      Raffle_MaxPeruser: Raffle_MaxPeruser,
      Raffle_RewardPerc: Raffle_RewardPerc,
      Raffle_noOfWinners: Raffle_noOfWinners,
      Raffle_desc: Raffle_desc,
      Raffle_image: ipfsimagehash,
      Raffle_title: Raffle_title,
      Raffle_subtitle: Raffle_subtitle,
      Raffle_startdate: starttimestamp,
      Raffle_enddate: endtimestamp,
      Raffle_Creator:Account
    };

    const rafflejson = {
      path: "aidicraft",
      content: JSON.stringify(metaraffle),
    };

    //console.log("rafflejson", rafflejson);

    const RaffleJsonFile = await ipfs.add( JSON.stringify(rafflejson));

    //console.log("RaffleJsonFileRaffleJsonFileRaffleJsonFile", RaffleJsonFile);

    const RaffleMeta = await Hash.of(JSON.stringify(rafflejson));
    //console.log("RaffleMeta: ", RaffleMeta);
    var localdata = false;
    try {
      localdata = JSON.parse(localStorage.getItem("ConnectionStatus1"));
    } catch (error) {
      localdata = false;
    }
    if (localdata) {
      var mintCall = null;
      var receipt = null;
      var handle = null;
      var tokenadd= Raffle_Token.split("-");
      var decimals = new BigNumber(10).pow(getDecimals(tokenadd[0]));

      try {
        var ContractCall = null;
        /*
        address _entryToken,
    uint256 _entryFee,
    uint256 _minEntriesForDraw,
    uint256 _maxEntriesForRaffle,
    uint256 _maxEntriesPerAddress,
    uint8 _totalRewardPercentage,
    uint256 _start,
    uint256 _end,
    uint256 _numberOfwinners,
    string memory ipfsdetails
        */
       //console.log("1");
        ContractCall = await RAFFLE_SMART_CONTRACT.methods.createRaffle(
          tokenadd[1],
          new BigNumber(Raffle_EntFee).multipliedBy(decimals).toFixed(),
          Number(Raffle_MinEntry).toString(),
          Number(Raffle_MaxEntry).toString(),
          Number(Raffle_MaxPeruser).toString(),
          Number(Raffle_RewardPerc).toString(),
          starttimestamp.toString(),
          endtimestamp.toString(),
          Number(Raffle_noOfWinners).toString(),
          RaffleMeta.toString()
        );
        //console.log("2");
        var estimategas = 0;
        let gasPrice = await web3.eth.getGasPrice();  
        gasPrice = new BigNumber(gasPrice.toString()).multipliedBy(1.3).integerValue().toFixed();
        var creationFee = new BigNumber(new BigNumber(CreateFee.toString()).integerValue()).toFixed();
        //console.log("3");
        try {
          estimategas = await RAFFLE_SMART_CONTRACT.methods.createRaffle(
            tokenadd[1],
            new BigNumber(Raffle_EntFee).multipliedBy(decimals).toFixed(),
            Number(Raffle_MinEntry).toString(),
            Number(Raffle_MaxEntry).toString(),
            Number(Raffle_MaxPeruser).toString(),
            Number(Raffle_RewardPerc).toString(),
            starttimestamp.toString(),
            endtimestamp.toString(),
            Number(Raffle_noOfWinners).toString(),
            RaffleMeta.toString()
          ).estimateGas({ from: Account, 
            value: creationFee,
            gasPrice:gasPrice });
            estimategas = new BigNumber(estimategas.toString()).multipliedBy(1.5).integerValue().toFixed();
        } catch (error) {
          Set_FormSubmitLoading("done");
          console.log("Contract call failed! " + error.toString());
          toast.error("Contract call failed! " + error.toString(), toasterOption);
          return false;
        }
       
        await ContractCall.send({ 
          from: Account, 
          value: creationFee,
          gasPrice:gasPrice,
          gasLimit:estimategas}).on(
          "transactionHash",
          (transactionHash) => {
            mintCall = transactionHash;
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash);
                clrtrns();
              }, 2000);
            }
          }
        );
        
        
      } catch (err) {
        Set_FormSubmitLoading("done");
        console.log("Eerrre", err);
      }
      async function clrtrns() {
        if (receipt != null) {
          clearInterval(handle);
          if (receipt.status === true) {
            var raffleid = receipt.logs[0].data;
            var transactionHash = mintCall;
            Set_FormSubmitLoading("done");
            //window.location.reload(false);
            //console.log("transactionHashtransactionHash", transactionHash);
            toast.success("Raffle Created Successfully", toasterOption);
            showModal("Raffle Created", "Raffle Created Successfully");
          }
        }
      }
    } else {
      toast.error("Please Connect Wallet to Create Raffle", toasterOption);
    }
  }

  const [show, setShow] = useState(false);
  const [modalContent, set_ModalContent] = useState("");
  const [modalTitle, set_ModalTitle] = useState("");

  const handleClose = () => setShow(false);
  async function showModal(title, content)
  {
    set_ModalContent(content);
    set_ModalTitle(title);
    setShow(true);
  }
  async function goHome()
  {
    window.location.href='/home'
  }

  return (
    <div className="App position-relative mt-nav">
      <div className="h-100vh bg-homebanner position-relative">
        {/* <HomeBanner /> */}

        <div className="main-center container">
          <Breadcrumb
            style={{
              position: "relative",
              marginBottom: "2rem",
              fontSize: "1rem",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
            separator=">"
          >
            <Breadcrumb.Item>
              <Link to="/">Explore</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create Raffle</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row">
            <div className="col-12">
              <div className="c-card-hoverable card right-card h-100">
                <div className="card-body d-flex align-content-start flex-column">
                  <div className="row">
                    <div className="col-lg-4 d-flex align-items-center">
                      {/*<img className="img-fluid"*/}
                      {/*     src={require("../assets/images/dummy.jpg")}/>*/}
                      {/* <CardItem /> */}

                      <Link className="c-card-hoverable card">
                        <div className="card-cover fl-cc pt-5">
                          {TokenFilePreUrl === "" ? (
                            <img
                              className="img-fluid"
                              loading="lazy"
                              src={
                                require("../assets/images/aidilogo.png")
                              }
                              alt="#"
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              loading="lazy"
                              src={
                                TokenFilePreReader !== ""
                                  ? TokenFilePreReader
                                  : require("../assets/images/aidilogo.png")
                              }
                              alt="#"
                            />
                          )}
                        </div>
                        <div>
                          <div
                            className="divider-horizontal"
                            role="separator"
                          />
                        </div>
                        <div className="card-body">
                          <span className="c-card-header">{Raffle_subtitle}</span>
                          <br />
                          <h3 className="typography">{Raffle_title}</h3>
                          <p className="text-ellipsis">
                            {Raffle_desc}
                          </p>
                          <br />
                          <div className="row">
                            <div className="col-6">
                              <div className="statistic">
                                <div className="statistic-title">
                                  <h5 className="typography mb-0">
                                    <span className="">Ticket Price</span>
                                  </h5>
                                </div>
                                <div className="statistic-content">
                                  <span className="statistic-content-value">
                                    {Raffle_EntFee} {Raffle_Token_name}
                                  </span>
                                </div>
                              </div>
                            </div>

{endDate!==0&&
 <div className="col-6">
 <div className="statistic">
   <div className="statistic-title">
     <h5 className="typography mb-0">
       <span className="">Closes in</span>
     </h5>
   </div>
   <div className="statistic-content">
     <span className="statistic-content-value">
       {/* 22h 22m 28s */}
       {SecondsToDay(endDate)}
     </span>
   </div>
 </div>
</div>
}
                           


                          </div>
                          {/* <div className="progress-line">
                            <div className="progress-outer">
                              <div className="progress-inner">
                                <div className="progress-bg" />
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="c-purchase">
                            <span className="purchase-text">Purchased</span>
                            <span className="purchase-details">10/100</span>
                          </div> */}
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-8 order-lg-first">
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <h1 className="typography font-weight-bold">
                              Create Raffle
                            </h1>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group className="mb-3" controlId="title">
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Title:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="Raffle_title"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_title && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_title}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group className="mb-3" controlId="subTitle">
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Sub Title:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="Raffle_subtitle"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_subtitle && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_subtitle}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="description"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Description:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Detailed description of the raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="Raffle_desc"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_desc && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_desc}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group controlId="coverImage" className="mb-3">
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Cover Image:
                              </Form.Label>
                              <Form.Control
                                type="file"
                                // accept="image/*"
                                name="image"
                                onChange={selectFileChange}
                              />

                              {ValidateError.TokenFilePreUrl && (
                                <Form.Text className="text-danger">
                                  {ValidateError.TokenFilePreUrl}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="contractAddress"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Entry Token Contract Address:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="The contract address of the entry token"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>
                              {/* <Form.Control
                                type="text"
                                placeholder="Enter Token Address"
                                name="Raffle_Token"
                               
                                onChange={inputChange}
                              /> */}
                              <Select
                                id="select_coinprice"
                                name="TokenCategory"
                                onChange={DropSelect}
                                options={config.currentNetwork == "Ethereum"?config.tokenssETH:config.tokenss}
                              />

                              {/* <Form.Select name="Raffle_Token"
                             
                                             onChange={DropSelect}>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>  */}
                              {ValidateError.Raffle_Token && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_Token}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group className="mb-3" controlId="entryFees">
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Entry Fees (without decimals):
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Fees for one entry of the raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="100"
                                name="Raffle_EntFee"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_EntFee && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_EntFee}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="minimumEntries"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Minimum Number of Entries:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Minimum number of entries required to draw the raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="10"
                                name="Raffle_MinEntry"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_MinEntry && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_MinEntry}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="maximumEntryCap"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Maximum Number of Entries:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Maximum number of entries allowed in the raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="50"
                                name="Raffle_MaxEntry"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_MaxEntry && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_MaxEntry}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="maximumEntries"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Maximum Number of Entries per Address:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="The maximum limit of entries per account"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="10"
                                name="Raffle_MaxPeruser"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_MaxPeruser && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_MaxPeruser}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="rewardPercentage"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Reward Percentage:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Percentage of total collected raffle entry funds that will be split equally among all the winners"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="50"
                                name="Raffle_RewardPerc"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_RewardPerc && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_RewardPerc}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6 c-datepicker mb-3">
                            <label className="d-flex align-items-center justify-content-between form-label">
                              Start time:
                              <div className="align-items-center d-flex">
                                <div className="c-checkbox-1 mr-3">
                                  <input
                                    type="checkbox"
                                    id="startdate"
                                    name="startdate"
                                    value="Immediate"
                                    onChange={DateSelector}
                                  />
                                  <label for="startdate">Immediate</label>
                                </div>
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Start time of the raffle, enter 0 for starting the raffle immediately after the creation"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </div>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                renderInput={(props) => (
                                  <TextField {...props} />
                                )}
                                minDate={new Date()}
                                label="disabled"
                                value={startDate}
                                disabled={startdisabled}
                                onChange={(newValue) => {
                                  setStartDate(newValue);
                                }}
                              />
                            </LocalizationProvider>
                            {ValidateError.Raffle_StartDate && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_StartDate}{" "}
                                </Form.Text>
                            )}
                          </div>
                          <div className="col-md-6 c-datepicker mb-3">
                            <label className="d-flex align-items-center justify-content-between form-label">
                              End Time:
                              <div className="align-items-center d-flex">
                                <div className="c-checkbox-1 mr-3">
                                  <input
                                    type="checkbox"
                                    id="enddate"
                                    name="enddate"
                                    value="Infinite"
                                    onChange={DateSelector}
                                  />
                                  <label for="enddate">Infinite</label>
                                </div>
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="End time of raffle, enter 0 if you want entries enabled till you draw the raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </div>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                renderInput={(props) => (
                                  <TextField {...props} />
                                )}
                                label="disabled"
                                value={endDate}
                                onChange={(newValue) => {
                                  setEndDate(newValue);
                                }}
                                disabled={enddisabled}
                                className="w-100"
                              />
                            </LocalizationProvider>
                            {ValidateError.Raffle_EndDate && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_EndDate}{" "}
                                </Form.Text>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="numberofWinners"
                            >
                              <Form.Label className="d-flex align-items-center justify-content-between">
                                Number of winners:
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  title="Number of winners for this raffle"
                                >
                                  <i className="far fa-question-circle text-muted" />
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="20"
                                name="Raffle_noOfWinners"
                                onChange={inputChange}
                              />
                              {ValidateError.Raffle_noOfWinners && (
                                <Form.Text className="text-danger">
                                  {ValidateError.Raffle_noOfWinners}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <div className="col-12">
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary btn-lg c-btn ms-2 ms-lg-4 px-4"
                          onClick={CreateItem}
                          disabled={FormSubmitLoading === "processing"}
                        >
                          {FormSubmitLoading === "processing" && (
                            <i
                              class="fa fa-spinner fa-spin mr-3 spinner_icon"
                              aria-hidden="true"
                              id="circle1"
                            ></i>
                          )}
                          Create
                        </button>
                      </div>
                    </div>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        centered
                        contentClassName="c-card-hoverable"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <h4 className="fw-bold mb-0">{modalTitle}</h4>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalContent}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="c-btn"
                            variant="primary"
                            onClick={goHome}
                          >
                            Home
                          </Button>
                        </Modal.Footer>
                      </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="javascript:" onClick={topFunction} id="return-to-top">
        <i className="icofont icofont-arrow-up" />
      </a>
    </div>
  );
}

export default Create;
