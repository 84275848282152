
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Countdown, { zeroPad } from "react-countdown";
import HomeBanner from "./components/HomeBanner";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const MINTComponent = (props) => {
  const { ...rest } = props;

  useEffect(() => {}, []);

  return (
    <div className="App position-relative mt-nav">
      <div className="h-100vh bg-homebanner position-relative">
        <HomeBanner />

        <div className="main-center container">
          <Breadcrumb
            style={{
              position: "relative",
              marginBottom: "2rem",
              fontSize: "1rem",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
            separator=">"
          >
            <Breadcrumb.Item>
              <Link to="/">Explore</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>FAQ</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row">
            <div className="col-12">
              <div className="c-card-hoverable card right-card h-100">
                <div className="card-body d-flex align-content-start flex-column">
                  <div id="faq" className="px-xl-3 py-xl-5">
                    <h1 className="typography font-weight-bold mb-4">FAQ</h1>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What is AidiRaffles?{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-7">
                              A new standalone utility of Aidiverse ecosystem
                              useful for blockchain projects and communities.
                              AidiRaffle system helps the projects in raising
                              funds from the community for critical listings,
                              events, marketing, etc. At the same time, it will
                              also let community members participate in fully
                              decentralized raffles to try their luck.
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Who can create a raffle?{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-7">
                              Any project can create a new fundraiser raffle
                              within 5 minutes using our intuitive web
                              application. Our secure raffle system is designed
                              to automatically pay out a certain percentage of
                              the total collected funds to all the winners.
                              Simply contact the Aidi Finance team at :{" "}
                              <a href="mailto:benjamin@aidiverse.com">
                                benjamin@aidiverse.com
                              </a>{" "}
                              or{" "}
                              <a href="https://t.me/aidi_official">
                                Aidi Finance Official Telegram
                              </a>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          How do you buy a raffle ticket?{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-7">
                              Connect your wallet to AidiRaffles.io. Have the
                              necessary funds available in the wallet to buy a
                              single or multiple ticket in the raffle of your
                              choice. Enter how many tickets you wish to buy and
                              approve the transaction. Remember to have funds
                              for gas in the wallet.
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          What tokens can be used on AidiRaffles?{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-7">
                              Any ERC20 token can be used to create a raffle
                              with. If you are a project and would like to use
                              your own ERC20 token for the raffle, please
                              contact the team for more details.
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          How does AidiRaffles choose a winner?{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-7">
                              AidiRaffles has integrated Chainlink’s VRF to
                              provide a provably fair draw to select random
                              winners, which can be verified on the blockchain.
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MINTComponent;
