import ButtonGroup from 'react-bootstrap/ButtonGroup'
import React from 'react';
import { NavLink } from "react-router-dom";


export default function HeaderRadio() {

    return (
        <ButtonGroup className="mb-0 c-btn-group">
            <NavLink className="btn btn-primary" to="/home" activeClassName="active">Explore</NavLink>
            <NavLink className="btn btn-primary" to="/myraffle" activeClassName="active">My Raffles</NavLink>
            <NavLink className="btn btn-primary" to="/faq" activeClassName="active">FAQ</NavLink>
        </ButtonGroup>
    );
}
