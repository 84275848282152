
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeBanner from "../../components/HomeBanner";
import CardItem from "../../components/CardItem";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import config from "../../../lib/config";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Web3 from "web3";
import "@metamask/legacy-web3";
import moment from "moment";
import axios from "axios";
import { watchNetwork } from '@wagmi/core'
import { getNetwork } from "@wagmi/core";
import RAFFLE_ABI from "../../../Abi/Raffle.json";
import { useAccount, useSwitchNetwork, useConnect, useDisconnect, useEnsName } from "wagmi";
const RAFFLE_ADDRESS = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? config.RAFFLE_CONTRACT_ETH: config.RAFFLE_CONTRACT : config.RAFFLE_CONTRACT_ETH;

var web3 = localStorage.getItem("Network") ? localStorage.getItem("Network") == "Ethereum"? new Web3(config.INFURA_URL): new Web3(config.INFURA_URL_POLY) : new Web3(config.INFURA_URL);


var RAFFLE_SMART_CONTRACT = new web3.eth.Contract(RAFFLE_ABI, RAFFLE_ADDRESS);

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

function HOME() {
  var { chain, chains } = getNetwork();
  var { address, isConnected } = useAccount();
  const [ShowFilterDrop, setShowFilterDrop] = useState(false);
  const [Account, set_Accounts] = useState("");
  const [ETHBalance, set_ETHBalance] = useState(0);
  const [AllRaffleIds, set_AllRaffleIds] = useState([]);
  const [AllRaffles, set_AllRaffles] = useState([]);
  const [Loading, set_Loading] = useState(false);
  async function ShowSort() {
    setShowFilterDrop(!ShowFilterDrop);
  }
  var netChange = "";

  function showLoader() {
    set_Loading(true);
  }
  function hideLoader() {
    set_Loading(false);
  }
  const unwatch = watchNetwork(async (network) => {
    try {
      set_AllRaffleIds([]);
      set_AllRaffles([]);
      netChange=network.chain.id;
      if(netChange == CHAIN_ID_ETH)
      {
        config.currentNetwork = "Ethereum";
        localStorage.setItem("Network", "Ethereum");
        RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
          RAFFLE_ABI,
          config.RAFFLE_CONTRACT_ETH
        );

      }else{
        config.currentNetwork = "Polygon";
        localStorage.setItem("Network", "Polygon");
        RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
          RAFFLE_ABI,
          config.RAFFLE_CONTRACT
        );
      }
      if (config.provider)
      {
        web3 = await new Web3(config.provider);
      }
      updateUI();
    }catch (error) {
      netChange = ""
    }
    })
  
  useEffect(
    () => {
      CheckWalletConnected();
    },
    [localStorage.getItem("ConnectionStatus1")],
    [address, isConnected, chain]
  );

  if(config.currentNetwork == "Ethereum")
  {
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT_ETH
    );

  }else{
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT
    );
  }
  if (config.provider)
  {
    web3 = new Web3(config.provider);
  }

  async function CheckWalletConnected() {

    if (config.provider)
    {
      showLoader();
      web3 = await new Web3(config.provider);
      if (web3) {
        const chainId = await web3.eth.getChainId();
        if (chainId.toString() == "1") {
          config.currentNetwork = "Ethereum";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT_ETH
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
        }else if (chainId.toString() == "137") {
          config.currentNetwork = "Polygon";
          RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
            RAFFLE_ABI,
            config.RAFFLE_CONTRACT
          );
          var owner = await RAFFLE_SMART_CONTRACT.methods
            .owner()
            .call();
          if(owner)
          {
            config.raffleContractOwner = owner;
          }
          await web3.eth.getAccounts(async function (error, result) {
            set_Accounts(result[0]);
          });
          updateUI();
        }
      }
    }
  }

  useEffect(async () => {
    updateUI()
  },[]);

  async function updateUI()
  {
    if(config.currentNetwork == "Ethereum")
  {
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT_ETH
    );

  }else{
    RAFFLE_SMART_CONTRACT = new web3.eth.Contract(
      RAFFLE_ABI,
      config.RAFFLE_CONTRACT
    );
  }
  if (config.provider)
  {
    web3 = new Web3(config.provider);
  }
    try {
      var raffleids = await RAFFLE_SMART_CONTRACT.methods
         .getAllActiveRaffles()
         .call();
       console.log("🚀 ~ file: Home.js ~ line 52 ~ useEffect ~ raffleids", raffleids)
         
 
       set_AllRaffleIds(raffleids);
       if (raffleids && raffleids.length > 0) {
         var AllRaffleData = [];
         var OwnRaffle = [];
         var ParRaffles = [];
         for (var i = 0; i < raffleids.length; i++) {
           var tempdetails = await RAFFLE_SMART_CONTRACT.methods
             .raffles(raffleids[i])
             .call();
             var TotalEntry = await RAFFLE_SMART_CONTRACT.methods
             .getTotalEntryFeesCollected(raffleids[i])
             .call();
             var Raffleclose = await RAFFLE_SMART_CONTRACT.methods
             .isRaffleClosed(raffleids[i])
             .call();
             
             var TotalEntryForAddress = 0;
             var acc = localStorage.getItem("Account");
             try {
               if(acc && acc.toString().length>40)
               {
                 TotalEntryForAddress = await RAFFLE_SMART_CONTRACT.methods
                   .getEntriesForAddressInRaffle(raffleids[i], acc)
                   .call();
                 TotalEntryForAddress = Number(TotalEntryForAddress.toString());
               }
             } catch (error) {
             }
             //console.log(tempdetails);
 
           if (tempdetails) {
             var ipfsresponse = await axios.get(
               `${config.IPFS_URL}` + tempdetails.ipfsdetails
             );
             //console.log(ipfsresponse);
             if (ipfsresponse && ipfsresponse.status === 200) {
               ipfsresponse= JSON.parse(ipfsresponse.data.content);
               AllRaffleData.push({
                 TokenAdd: ipfsresponse.Raffle_Token,
                 EntryFee: ipfsresponse.Raffle_EntFee,
                 Min_entry: ipfsresponse.Raffle_MinEntry,
                 Max_entry: ipfsresponse.Raffle_MaxEntry,
                 Max_Per_user: ipfsresponse.Raffle_MaxPeruser,
                 RewardPerc: ipfsresponse.Raffle_RewardPerc,
                 NoOfWinners: ipfsresponse.Raffle_noOfWinners,
                 Description: ipfsresponse.Raffle_desc,
                 Image: ipfsresponse.Raffle_image,
                 Title: ipfsresponse.Raffle_title,
                 SubTitle: ipfsresponse.Raffle_subtitle,
                 StartDate: ipfsresponse.Raffle_startdate,
                 Enddate: ipfsresponse.Raffle_enddate,
                 Raffle_id: raffleids[i],
                 TotalEntry: TotalEntry,
                 Raffleclose: Raffleclose,
                 Bought:TotalEntryForAddress
               });
               //console.log("AllRaffleData: ", JSON.stringify(AllRaffleData));
             }
           }
         }
         //console.log("AllRaffleDataAllRaffleDataAllRaffleData", AllRaffleData);
         hideLoader();
         set_AllRaffles(AllRaffleData);
       }
       hideLoader();
     } catch (e) {
       hideLoader();
       console.log("erererere", e);
     }
  }

  return (
    <div className="App position-relative mt-nav">
      {Loading &&
      <Loader />}
      <div className="h-100vh bg-homebanner position-relative">
        {/* <HomeBanner /> */}

        <div className="main-center container">
          <div className="text-end">
            <div className="position-relative z-index-1 ml-md-auto">
              {/* <p>
                                <a className="btn btn-primary c-border-radius" onClick={ShowSort}>
                                    <span className="c-heading-gradient">
                                       Recently Added
                                       <i className="fas fa-filter ml-2"/>
                                    </span>
                                </a>
                            </p> */}

              <div
                className={
                  ShowFilterDrop
                    ? "collapse drobdow_toggle_menu right show"
                    : "collapse drobdow_toggle_menu right"
                }
                id="Recently_added"
              >
                <div className="card card-body p-3" id="nav-tab-recently">
                  <div href="#" className="default_select">
                    Sort by
                  </div>
                  <a href="#">
                    <i className="fa fa-history mr-3 text-white" />
                    <span>
                      Recently added
                      <i className="fas fa-check text-white" />
                    </span>
                  </a>
                  <a href="#">
                    <i className="fa fa-sort-amount-up mr-3 text-white" />
                    <span>
                      Price: Low to High
                      <i className="fas fa-check text-white" />
                    </span>
                  </a>
                  <a href="#">
                    <i className="fa fa-sort-amount-down mr-3 text-white" />
                    <span>
                      Price: High to Low
                      <i className="fas fa-check text-white" />
                    </span>
                  </a>
                  <a href="#">
                    <i className="fa fa-hourglass-end mr-3 text-white" />
                    <span>
                      Raffle ending soon
                      <i className="fas fa-check text-white" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {AllRaffles &&
              AllRaffles.length > 0 &&
              AllRaffles.map((item) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4">
                    <CardItem item={item} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <a href="javascript:" onClick={topFunction} id="return-to-top">
        <i className="icofont icofont-arrow-up" />
      </a>
    </div>
  );
}

export default HOME;
