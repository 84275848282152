import { WalletClient, getWalletClient } from '@wagmi/core'
import { providers } from 'ethers'
import config from './config';
import { connect } from '@wagmi/core'
import { InjectedConnector } from '@wagmi/core/connectors/injected'
 
export async function walletClientToSigner(walletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  if(signer.provider != null && signer.provider != undefined)
  {
    config.provider = signer.provider.provider;
    localStorage.setItem("config_provider",config.provider);
  }
  return {signer: signer, provider: provider};
}

/** Action to convert a viem Wallet Client to an ethers.js Signer. */
export async function getEthersSigner(chainId) {
  if(chainId)
  {
    const walletClient = await getWalletClient({ chainId:chainId })
    
    if (!walletClient) 
    {
      return null;
    }
    var signer = await walletClientToSigner(walletClient);
    //console.log("walletClient: ", signer, chainId)
    return signer;
  }
  return null;
}

export async function getConnector() {
  const result = await connect({
    connector: new InjectedConnector(),
  })
  return result;
}