import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import HeaderRadio from "./HeaderRadio";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BigNumber } from "bignumber.js";
import Web3 from "web3";
import "@metamask/legacy-web3";
import { toast } from "react-toastify";
import config from "../../lib/config";
import { watchNetwork } from '@wagmi/core'
import { useAccount, useSwitchNetwork, useDisconnect, useEnsName } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {getEthersSigner} from "../../lib/ethers";
toast.configure();
let toasterOption = config.toasterOption;

export default function Header() {
  var { address, isConnected } = useAccount();
  var [Network, set_Network] = React.useState("Ethereum");
  var { chain, chains } = getNetwork();
  const [headerClassName, setHeaderClassName] = useState("navbar-transparent");
  const [Account, set_Accounts] = useState("");
  const [HalfAddress, set_HalfAddresss] = useState("");

  const [ETHBalance, set_ETHBalance] = useState(0);
  const [ConnectionStatus, set_ConnectionStatus] = useState(false);
  const CHAIN_ID_ETH = 1;
  const CHAIN_ID_POLYGON = 137;
  var netChange = "";
  const unwatch = watchNetwork(async (network) => {
    try {
      netChange=network.chain.id;
      if(netChange == CHAIN_ID_ETH)
      {
        set_Network("Ethereum");
        config.currentNetwork = "Ethereum";
        localStorage.setItem("Network", "Ethereum");
        await getEthersSigner(CHAIN_ID_ETH);
      }else{
        set_Network("Polygon");
        config.currentNetwork = "Polygon";
        localStorage.setItem("Network", "Polygon");
        await getEthersSigner(CHAIN_ID_POLYGON);
      }
    } catch (error) {
      netChange = ""
    }
    })
  
  const handleScroll = (headerClassName) => {
    if (headerClassName === "navbar-transparent" && window.pageYOffset >= 50) {
      setHeaderClassName("");
    } else if (
      headerClassName !== "navbar-transparent" &&
      window.pageYOffset < 50
    ) {
      setHeaderClassName("navbar-transparent");
    }
  };

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  useEffect(
    () => {
      console.log(address, netChange);
      loadUI()
    },
    [netChange],[address, isConnected]
  );

  async function loadUI()
  {
    if (address && address != "" && isConnected) {
      if (chain && chain.id && (chain.id == CHAIN_ID_ETH || chain.id == CHAIN_ID_POLYGON )) {
        if(chain.id == CHAIN_ID_ETH)
        {
          set_Network("Ethereum");
          config.currentNetwork = "Ethereum";
          localStorage.setItem("Network", "Ethereum");
        }else{
          set_Network("Polygon");
          config.currentNetwork = "Polygon";
          localStorage.setItem("Network", "Polygon");
        }
        await getEthersSigner(chain.id);
        localStorage.setItem("ConnectionStatus1", true);
        updateUI(address);
      } else {
        set_Network("Wrong Network");
        disconnect();
      }
    }else{
      disconnect();
    }
  }

  async function updateUI(address)
  {
    set_ConnectionStatus(true);
    localStorage.setItem("Account", address);
    set_Accounts(address);
    var halfadd = HalfAddressSet(address);
    set_HalfAddresss(halfadd);
  }
 
  function HalfAddressSet(addr) {
    if (addr) {
      return addr.substring(0, 5) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  }

  
  async function disconnect() {
    localStorage.removeItem("Account");
    set_Accounts("");
    localStorage.removeItem("ConnectionStatus1");
    localStorage.removeItem("ConnectionType");
    localStorage.removeItem("walletconnect");
    set_ConnectionStatus(false);
    //console.log("disconnect");
  }

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className={"dark z-index-1 " + headerClassName}
    >
      <div className="container">
        <Navbar.Brand
          className="d-flex align-items-center justify-content-between"
          href="/home"
        >
          <div className="navbar-header">
            <div className="logo">
              <a href="/home">
                <img
                  className="logo-dark"
                  loading="lazy"
                  src={
                    require("../../assets/images/logo-black-full.png")
                  }
                />
                <img
                  className="logo logo-scrolled logo-light"
                  loading="lazy"
                  src={
                    require("../../assets/images/logo-white-dot.png")
                  }
                />
              </a>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Mobile view */}
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="z-index-1 flex-grow-0 mx-auto"
        >
          <Nav className="me-auto">
            <Nav.Link className="">
              <HeaderRadio />
            </Nav.Link>
            <Nav.Link className="d-lg-none">
              <Link to="/create">
                <button className="btn btn-primary c-btn">Create Raffle</button>
              </Link>
            </Nav.Link>
            <Nav.Link className="d-lg-none">
              <div>
                {/* {ConnectionStatus && (
                  <Dropdown>
                    <Dropdown.Toggle className="c-btn" variant="primary">
                      {HalfAddress}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={disconnect}>
                        Disconnect
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {!ConnectionStatus && (
                  <Dropdown>
                    <Dropdown.Toggle className="c-btn" variant="primary">
                      Connect Wallet
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={connectMetamask}>
                        Metamask
                      </Dropdown.Item>
                      <Dropdown.Item onClick={connectWC}>
                        Wallet Connect
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}
                <ConnectButton
                />
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Nav.Link className="pr-0 d-none d-lg-block mr-3">
          <Link to="/create">
            <button className="btn btn-primary c-btn ">Create Raffle</button>
          </Link>
        </Nav.Link>
        <Nav.Link className="pr-0 d-none d-lg-block">
          <div>
            {/* {ConnectionStatus && (
              <Dropdown>
                <Dropdown.Toggle className="c-btn" variant="primary">
                  {HalfAddress}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={disconnect}>Disconnect</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!ConnectionStatus && (
              <Dropdown>
                <Dropdown.Toggle className="c-btn" variant="primary">
                  Connect Wallet
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={connectMetamask}>
                    Metamask
                  </Dropdown.Item>
                  <Dropdown.Item onClick={connectWC}>
                    Wallet Connect
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )} */}
            <ConnectButton
                />
          </div>
        </Nav.Link>
      </div>
    </Navbar>
  );
}
