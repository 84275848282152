import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HOME from "./components/Home";
import '@rainbow-me/rainbowkit/styles.css';

window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    let toTop = document.getElementById("return-to-top");
    if (toTop != null && toTop.style != null && toTop.style.display != null) {
        if ((document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)) {
            toTop.style.display = "block";
        } else {
            toTop.style.display = "none";
        }
    }
}


function App() {
    return (

        <>
        <HOME/>
        </>
    );
}

export default App;
