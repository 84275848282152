let EnvName = "production"

let toasterOption = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  };
  
const tokenss = [
  { value: "USDC-0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", label: "USDC", decimals: 6, transferFee: 0},
  { value: "USDT-0xc2132D05D31c914a87C6611C10748AEb04B58e8F", label: "USDT", decimals: 6, transferFee: 0},
  { value: "WMATIC-0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", label: "WMATIC", decimals: 18, transferFee: 0},
];

const tokenssETH = [
  { value: "AIDI-0xE3e24b4eA87935e15bbE99A24E9AcE9998e4614d", label: "AIDI", decimals: 18, transferFee: 0},
  { value: "USDC-0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", label: "USDC", decimals: 6, transferFee: 0},
  { value: "USDT-0xdAC17F958D2ee523a2206206994597C13D831ec7", label: "USDT", decimals: 6, transferFee: 0},
  { value: "WETH-0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", label: "WETH", decimals: 18, transferFee: 0},
];
var provider = null;
var networkversion = '137';
var currentNetwork = 'Ethereum';
var rafflecontract= '0x3043d862c22D9D80aB43F4578771FF4C1ea3BEbD';
var rafflecontractETH= '0x06faF68C01a78E46384D43114B22d34C4c81a5F3';
let currencySymbol     = 'MATIC';
var   Back_URL  
var PrefferedNetwork 
var web3config={}
let infuraurl = "https://rpc.ankr.com/eth"
let infuraurlPoly = "https://rpc.ankr.com/polygon"
let raffleContractOwner = "0xCbd7bE99Fc6Fb3719bea21bB6E6D40a7C9Ec50bF"
let WC_PROJECT_ID = "94db0752e5ae44dfb67432660df4418f"
let ALCHEMY_API = "OeVQBVYlciF1wItsfVvjXZBWsFdiMTJQ";

export function getDecimals(label)
{
  if(currentNetwork == "Polygon")
  {
    for(var i in tokenss)
    {
      var token = tokenss[i];
      if(token["label"] == label)
      {
        return Number(token["decimals"]);
      }
    }
  }else if(currentNetwork == "Ethereum")
  {
    for(var i in tokenssETH)
    {
      var token = tokenssETH[i];
      if(token["label"] == label)
      {
        return Number(token["decimals"]);
      }
    }
  }
  
  return 18;
}

export function getTransferFee(label)
{
  if(currentNetwork == "Polygon")
  {
    for(var i in tokenss)
    {
      var token = tokenss[i];
      if(token["label"] == label)
      {
        return Number(token["transferFee"]);
      }
    }
  }else if(currentNetwork == "Ethereum")
  {
    for(var i in tokenssETH)
    {
      var token = tokenssETH[i];
      if(token["label"] == label)
      {
        return Number(token["transferFee"]);
      }
    }
  }
  return 0;
}


var key = {
    provider:provider,
    toasterOption: toasterOption,
    RAFFLE_CONTRACT:rafflecontract,
    RAFFLE_CONTRACT_ETH:rafflecontractETH,
    networkVersion:networkversion,
    decimalvalues:10**18,
    usdDecimalValues:10**6,
    currentNetwork:currentNetwork,
    ipfsurl : "https://ipfs.infura.io/ipfs/",
    currencySymbol:currencySymbol,
    Back_URL:Back_URL,
    PrefferedNetwork:PrefferedNetwork,
    web3config:web3config,
    MAX_APPROVAL:"115792089237316195423570985008687907853269984665640564039457584007913129639935", 
    INFURA_URL:infuraurl,
    INFURA_URL_POLY:infuraurlPoly,
    //IPFS_URL:"https://ipfs.io/ipfs/"
    IPFS_URL:"https://aidicraft.infura-ipfs.io/ipfs/",
    tokenss:tokenss,
    tokenssETH:tokenssETH,
    raffleContractOwner:raffleContractOwner,
    WcProjectID: WC_PROJECT_ID,
    AlchemyApiKey: ALCHEMY_API,
  };
  
  
  export default key;